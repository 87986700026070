import React, { useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { getCmsClient } from '../../common/utilities/cmsClient';
import { getTendersContent } from '@kfc-global/react-shared/redux/Actions/PaymentsContentAction';
import { Config, PAYMENT_TENDER_TYPES } from '../../common/constants/SharedConstants';

export const getIcons = ({
  icon: {
    fields: {
      desktopImage: {
        fields: { file: icon },
      },
    },
  },
}) => ({ icon });

export const redioIconClassName = tenderName => {
  let classname = '';
  switch (tenderName) {
    case PAYMENT_TENDER_TYPES.PHONEPE:
      classname = 'phonePay';
      break;
    case PAYMENT_TENDER_TYPES.GOOGLE_PAY_SPOT:
      classname = 'googlePay';
      break;
    case PAYMENT_TENDER_TYPES.PAYTM:
      classname = 'paytm';
      break;
    default:
  }
  return classname;
};

export const selectTenders = ({ paymentsContentReducer: { tendersContent: { items = [] } = {} } = {} }) => items;
export default function PaymentOptions({ updateCheckoutDetails, checkoutDetails, children }) {
  const { tender, allowedTenders, isValidateBasketActionCompleted } = checkoutDetails || {};
  const dispatch = useDispatch();
  const [selectedTender, setSelectedTender] = useState(tender?.name);

  useEffect(() => {
    dispatch(
      getTendersContent({
        object: getCmsClient(),
        contentFull: {
          content_type: 'tenders',
          include: 5,
          select: 'sys.id,fields',
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTendersList = createSelector(selectTenders, tenders => {
    const result = [];
    const module = JSON.parse(localStorage.getItem(Config.Setting.Storage.Store) ?? null)?.module;
    const moduleHasTender = value => {
      return module?.tenders?.includes(value);
    };

    tenders.forEach(({ fields: tenderVal }) => {
      if (!module || moduleHasTender(tenderVal?.title)) {
        const temp = {
          ...tenderVal,
          ...('icon' in tenderVal && { ...getIcons(tenderVal) }),
        };

        result.push(temp);
      }
    });

    return result.sort(({ title }, { title: newTitle }) => (title > newTitle ? 1 : (newTitle > title && -1) || 0));
  });

  const tendersList = useSelector(getTendersList);

  useEffect(() => {
    if (!selectedTender && allowedTenders?.length > 0 && tendersList?.length > 0 && isValidateBasketActionCompleted) {
      const defaultTender = tendersList.find(tenderArg => tenderArg.title === allowedTenders[0]);
      handleChange(
        { target: { value: defaultTender?.title } },
        defaultTender?.displayName,
        defaultTender?.icon?.url || '',
      );
    }
  }, [isValidateBasketActionCompleted]);

  const handleChange = (event, displayName, url) => {
    updateCheckoutDetails?.({
      isValid: false,
    });
    const tenderName = event.target.value;
    setSelectedTender(tenderName);
    checkoutDetails?.cartData?.paymentGateway === PAYMENT_TENDER_TYPES?.PHONEPE &&
      updateCheckoutDetails({ displayErrorMessage: true });
    updateCheckoutDetails?.({
      tender: { ...tender, name: tenderName, payload: { displayName, url } }, //
    });
    updateCheckoutDetails?.({
      isValid:
        tenderName === PAYMENT_TENDER_TYPES.PAY_BY_CASH ||
        tenderName === PAYMENT_TENDER_TYPES.PAYTM ||
        tenderName === PAYMENT_TENDER_TYPES.PHONEPE ||
        tenderName === PAYMENT_TENDER_TYPES.UPI ||
        tenderName === PAYMENT_TENDER_TYPES.GOOGLE_PAY_SPOT,
    });
  };

  return (
    <div className='payment-option-container'>
      {tendersList &&
        allowedTenders &&
        tendersList?.map(({ displayName, title: tenderName, shortDescription, icon: { url } = {} }) => (
          <>
            {allowedTenders?.includes(tenderName.toLowerCase()) && (
              <div tabIndex='0' className='payment-option'>
                <div className='payment-option-header'>
                  <label
                    for={tenderName}
                    className='tender-type-label radioBtnsOption'
                    data-testid={`radio-label-${tenderName}`}
                  >
                    <input
                      type='radio'
                      id={tenderName}
                      data-testid={`radio-handler-${tenderName}`}
                      name='Tenders'
                      value={tenderName}
                      checked={selectedTender === tenderName}
                      onChange={event => handleChange(event, displayName, url)}
                    />
                    <span className={redioIconClassName(tenderName)}></span>
                    {!!displayName &&
                      tenderName !== PAYMENT_TENDER_TYPES.PAYTM &&
                      tenderName !== PAYMENT_TENDER_TYPES.PHONEPE &&
                      displayName}
                    {!!url && (
                      <>
                        <img
                          src={url}
                          alt={`${redioIconClassName(tenderName)}`}
                          className={`tender-image ${
                            tenderName === PAYMENT_TENDER_TYPES.PHONEPE ? 'phonepay-img' : ''
                          }`}
                        />
                      </>
                    )}
                    {!!displayName && tenderName === PAYMENT_TENDER_TYPES.PHONEPE && displayName}
                    {!!shortDescription && <p>{shortDescription}</p>}
                  </label>
                </div>

                {(tender?.name === PAYMENT_TENDER_TYPES.CREDIT_CARD ||
                  tender?.name === PAYMENT_TENDER_TYPES.NET_BANKING ||
                  tender?.name === PAYMENT_TENDER_TYPES.OTHER_WALLET ||
                  tender?.name === PAYMENT_TENDER_TYPES.GIFT_CARD) &&
                  tenderName === tender?.name && (
                    <div className={`payment-option-child ${tenderName === tender?.name ? 'active' : ''}`}>
                      {children}
                    </div>
                  )}
              </div>
            )}
          </>
        ))}
    </div>
  );
}

import {
  clearPastOrderAction,
  resetOrderAction,
  createOrderAction,
  validateOrderAction,
} from '@kfc-global/react-shared/redux/Actions/orderAction';
import { resetPaymentStatus } from '@kfc-global/react-shared/redux/Actions/PaymentsAction';
import { resetPostOrder } from '@kfc-global/react-shared/redux/Actions/PostOrderAction';
import { resetGuestOtp } from '@kfc-global/react-shared/redux/Actions/GuestOtpAction';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import ImageComponent from 'atoms/Image';
import {
  DISPOSITION_KEYS,
  EMAIL,
  LOCAL_STORAGE_KEYS,
  PHONE,
  CHECKOUT_CONSTANTS,
  SESSION_STORAGE_KEYS,
  ANALYTICS_SHARED_CONSTANTS,
  PAYMENT_GATEWAYS,
  PAYMENT_TENDER_TYPES,
  OPEN,
  checkoutUserOptionPayload,
  PICK_UP,
  GUEST_OTP_VERIFICATION_FAILURE,
  GUEST_OTP_VERIFICATION_SUCCESS,
  NOT_AVAILABLE,
  COUNTRY_CODE,
} from 'common/constants/SharedConstants';
import {
  calculatePrice,
  getEmbeddedErrorMessageAnalytic,
  getTernaryValue,
  getTransactionFailedAnalytic,
  isDeliveryDisposition,
  isEmptyEvery,
  isEmptyObject,
  isMobileDevice,
  isPickupDisposition,
  isValidFullName,
  isValueContainsRestrictionWords,
  safeUpperCase,
  userParameterObject,
  isSupportedTenantCode,
  getFullAddressDetails,
  isValidEmailFormat,
  getValueOrDefaultObject,
  isCateringDisposition,
  getCouponDetails,
  isStoreTemproryClosed,
  itemFilter,
  foodlineProducts,
} from 'common/utilities/utils';
import {
  addContactInfoClickAnalytics,
  beginCheckoutClickAnalyticsStep1,
  guestContactDetailsClickAnalytics,
  updatePaymentAnalytics,
  onCheckoutAnalytics,
} from 'common/utilities/analyticsUtils';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import { ChannelManager } from 'common/manager';
import { isValidValue } from './utils';
import { CATERING } from '../constants/SharedConstants';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { checkForScheduledTime } from 'organisms/ScheduleOrder/ScheduleOrderUtils/ScheduleOrderUtils';
import { paymentTrancationErrorHandler } from 'common/utilities/PaymentUtils';
import { getEnablePaymentCtaForPickupMethod } from 'organisms/Payment/presentation/templates/PaymentViewTemplates';
import { getCardType } from './PaymentUtils';
export const passStreetNoToOrderCheck = checkoutDetails => {
  return checkoutDetails?.passStreetNoToOrder && isDeliveryDisposition(checkoutDetails?.service)
    ? {
        ...checkoutDetails?.placeOrderRequest?.request,
        basketId: checkoutDetails?.basketId,
        customProperties: checkoutDetails?.streetNumberValue,
      }
    : {
        ...checkoutDetails?.placeOrderRequest?.request,
        basketId: checkoutDetails?.basketId,
      };
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const dispatchOrderActions = params => {
  const { checkoutDetails, updateCheckoutDetails } = params;
  const {
    dispatch,
    isClickPlaceOrder,
    dispatchOrderAction,
    userSubscription,
    userParams,
    foodLines,
    currencyConversionFactor,
    tender,
    categories,
    placeOrderRequest,
    orderId,
    customerId,
    tenantId,
    basketId,
    isUpdatedOrderActionRequest,
    collectionPoint,
    enableValidateOrder,
    deliveryPartners,
    eventCode,
  } = checkoutDetails;
  const isitRNA = checkoutDetails?.RNAtoken && {
    overrideRequestHeader: true,
    authToken: checkoutDetails?.RNAtoken,
    appchannel: checkoutDetails?.appchannel,
  };

  if (isClickPlaceOrder && isUpdatedOrderActionRequest && dispatchOrderAction) {
    userSubscription?.value && !userParams?.loggedIn && guestContactDetailsClickAnalytics();

    updatePaymentAnalytics({ foodLines }, currencyConversionFactor, tender, categories);
    const { request } = placeOrderRequest;
    request.tenders = getTenderPayload(checkoutDetails);
    if (userSubscription?.value) {
      request.marketingPreferences = [EMAIL, PHONE];
    }
    request.deliveryPartners = deliveryPartners ? deliveryPartners : request?.deliveryPartners;
    if (enableValidateOrder) {
      dispatch(
        validateOrderAction({
          tenantId,
          customerId,
          request: {
            ...request,
            basketId,
            id: orderId,
            collectionPoint,
            eventCode,
          },
          ...isitRNA,
          fullStorySessionUrl: checkoutDetails?.RNAtoken ? '' : window?.FS?.getCurrentSessionURL(),
          successCallback: () => updateCheckoutDetails({ isCreateOrderValidateCompleted: true }),
          errorCallback: error => {
            paymentTrancationErrorHandler(params, error);
          },
        }),
      );
    } else {
      dispatch(
        createOrderAction({
          tenantId,
          customerId,
          request: {
            ...request,
            basketId,
          },
        }),
      );
    }
  }
};
export const isCateringSomeOneElseContactDetailsCheck = checkoutDetails =>
  isSupportedTenantCode() &&
  checkoutDetails?.isCateringSomeOneElseContactDetailsEnabled &&
  isCateringDisposition(checkoutDetails?.service) &&
  checkoutDetails?.userParams?.loggedIn;

export const dispatchResetOrderAction = dispatch => !isSupportedTenantCode() && dispatch(resetOrderAction());

export const resetOrderReducer = checkoutDetails => {
  const { dispatch, orderReducerError, orderReducerResponse } = checkoutDetails;
  if (orderReducerError && orderReducerResponse) {
    dispatchResetOrderAction(dispatch);
    dispatch?.(clearPastOrderAction({ value: [] }));
  }
  dispatch?.(resetPostOrder());
  sessionStorage.removeItem(LOCAL_STORAGE_KEYS.ORDER_TRACKING);
  sessionStorage.removeItem(SESSION_STORAGE_KEYS.POST_PAYMENT_TYPE);
};

export const resetGuestOtpReducer = (checkoutDetails, updateCheckoutDetails) => {
  if (checkoutDetails?.enableGuestOtpCheckout) {
    checkoutDetails?.dispatch?.(resetGuestOtp());
    updateCheckoutDetails({
      guestOtpValidationFailedAttemptsCount: 0,
      guestOtpValidationMessge: GUEST_OTP_VERIFICATION_SUCCESS,
    });
  }
};

export const executeAnalyticsForInStore = (service, CollectionPoint) => {
  if (service === 'PICKUP') {
    CollectionPoint = CollectionPoint === 'leaveatmydoor' ? 'in-store' : 'KFC to your Car';
  }
  let setAttributePayload = sessionStorage.getItem('setAttributePayload');
  if (setAttributePayload) {
    setAttributePayload = JSON.parse(setAttributePayload);
    setAttributePayload.CollectionPoint = CollectionPoint;
    userParameterObject(setAttributePayload);
  }
};

export const isMobileClassName = isMobile => {
  return isMobile ? ' mobile' : '';
};

export const isMobilePadding = isMobile => {
  return isMobile ? '' : ' px-rt-5px';
};

export const getCreateOrderReducerErrorMessage = createOrderReducer => {
  const errorsOrderDetails = createOrderReducer?.response?.response
    ? createOrderReducer?.response?.response?.data
    : createOrderReducer?.response;
  let orderErrorMessage = '';

  if (errorsOrderDetails?.errors && errorsOrderDetails?.errors.length > 0) {
    orderErrorMessage = Array.isArray(errorsOrderDetails?.errors)
      ? errorsOrderDetails?.errors[0]
      : errorsOrderDetails?.errors;
  } else if (errorsOrderDetails?.errorMessages) {
    orderErrorMessage = Array.isArray(errorsOrderDetails?.errorMessages)
      ? errorsOrderDetails?.errorMessages[0]
      : errorsOrderDetails?.errorMessages;
  }
  orderErrorMessage = orderErrorMessage?.message ? orderErrorMessage?.message : orderErrorMessage;
  return orderErrorMessage;
};

export const getCheckoutErrorMessage = params => {
  const { orderReducer, showPaymentError, showErrorPayment, showPaymentPreValidationError } = params;
  if (showPaymentPreValidationError || showErrorPayment) {
    return translateWithI18Next(TRANSLATE_MAPPING_KEY.PAYMENT_PRE_VALIDATION_FAILED_MESSAGE);
  } else if (showPaymentError) {
    return translateWithI18Next(TRANSLATE_MAPPING_KEY.PAYMENT_FAILED_MESSAGE);
  } else if (orderReducer && orderReducer?.error) {
    return getCreateOrderReducerErrorMessage(orderReducer);
  }
  return '';
};

export const showCheckoutPageErrorAnalytic = (orderReducer, showPaymentError, showPaymentPreValidationError) => {
  const errorMessage = getCheckoutErrorMessage({ orderReducer, showPaymentError, showPaymentPreValidationError });

  if (errorMessage !== '') {
    getEmbeddedErrorMessageAnalytic(document.title, errorMessage);
    getTransactionFailedAnalytic(errorMessage);
  }
};

export const showCheckoutPageError = (orderReducer, checkoutDetails, updateCheckoutDetails) => {
  const { showPaymentError, showErrorPayment, showPaymentPreValidationError, displayErrorMessage } = checkoutDetails;
  let errorMessage = '';
  if (orderReducer?.error || showPaymentError || showErrorPayment || showPaymentPreValidationError) {
    errorMessage = getCheckoutErrorMessage({
      orderReducer,
      showPaymentError,
      showErrorPayment,
      showPaymentPreValidationError,
    });

    if (checkoutDetails?.tender?.name !== '' && errorMessage !== '' && !displayErrorMessage) {
      window?.scrollTo(0, 0);
      updateCheckoutDetails({ displayErrorMessage: true });
    }
  }

  return (
    errorMessage !== '' && (
      <div className='row row-cols-12 mt-10px payment-failed-error-box'>
        <ImageComponent srcFile={IMAGE_PATH.Error} className='mt-3px mr-5px' />
        <div className='ml-10px'>{errorMessage}</div>
      </div>
    )
  );
};

export const isValidCateringSomeOneElseContactDetails = checkoutDetails => {
  const { userFirstName, userLastName, userPhone, userEmail } = checkoutDetails?.cateringSomeOneElseContactDetails;
  return (
    isValidValue(userFirstName?.value) &&
    isValidValue(userLastName?.value) &&
    isValidValue(userPhone?.value) &&
    isValidValue(userEmail?.value)
  );
};

export const validCateringSomeOneElseContactDetails = (checkoutDetails, updateCheckoutDetails) => {
  if (checkoutDetails?.isCateringSomeOneElseContactDetailsEnabled && !checkoutDetails?.paymentEnabled) {
    const { userFirstName, userLastName, userPhone, userEmail } = checkoutDetails?.cateringSomeOneElseContactDetails;

    updateCateringSomeOneElseContactDetails(
      {
        target: { value: userFirstName?.value, name: TRANSLATE_MAPPING_KEY?.FIRST_NAME_LABEL, checked: false },
      },
      checkoutDetails,
      updateCheckoutDetails,
    );
    updateCateringSomeOneElseContactDetails(
      {
        target: { value: userLastName?.value, name: TRANSLATE_MAPPING_KEY?.LAST_NAME_LABEL, checked: false },
      },
      checkoutDetails,
      updateCheckoutDetails,
    );

    updateCateringSomeOneElseContactDetails(
      {
        target: { value: userPhone?.value, name: TRANSLATE_MAPPING_KEY?.PHONE, checked: false },
      },
      checkoutDetails,
      updateCheckoutDetails,
    );
    updateCateringSomeOneElseContactDetails(
      {
        target: { value: userEmail?.value, name: TRANSLATE_MAPPING_KEY?.EMAIL, checked: false },
      },
      checkoutDetails,
      updateCheckoutDetails,
    );
  }
};

export const updateCateringSomeOneElseContactDetails = (event, checkoutDetails, updateCheckoutDetails) => {
  const cateringSomeOneElseContactDetails = { ...checkoutDetails?.cateringSomeOneElseContactDetails };
  const update = () => {
    const inputValue = event.target.value;
    const inputName = event.target.name;

    const { userFirstName, userLastName, userPhone, userEmail } = cateringSomeOneElseContactDetails;

    return getValidateAndGetFieldValue({
      inputName: inputName.substr(0, inputName.indexOf('_' + CATERING)),
      inputValue,
      checkoutDetails,
      userFirstName,
      userLastName,
      userPhone,
      userEmail,
    });
  };
  updateCheckoutDetails({ cateringSomeOneElseContactDetails: { ...cateringSomeOneElseContactDetails, ...update() } });
};

export const getOrderDefaultRequest = checkoutDetails => {
  const {
    placeOrderRequest,
    cartDataCustomer,
    customerId,
    userFullName,
    userFirstName,
    userLastName,
    userPhone,
    userEmail,
  } = checkoutDetails;
  return {
    ...placeOrderRequest.request,
    customer: {
      id: customerId,
      firstName:
        getTernaryValue(checkoutDetails?.isEnableFullName, userFullName?.value, userFirstName?.value) ||
        cartDataCustomer?.firstName ||
        '',
      lastName:
        getTernaryValue(checkoutDetails?.isEnableFullName, userFullName?.value, userLastName?.value) ||
        cartDataCustomer?.lastName ||
        '',
      email: userEmail?.value || cartDataCustomer?.email,
      phoneNumber: userPhone?.value?.split(' ').join('') || cartDataCustomer?.phone,
      couponCodes: [],
      customProperties: null,
    },
    tenders: getTenderPayload(checkoutDetails),
  };
};

export const getTotalAmount = checkoutDetails =>
  checkoutDetails?.driverTippingCharge > 0 && checkoutDetails?.driverdeliveryTip === 0
    ? checkoutDetails?.total - checkoutDetails?.driverTippingCharge
    : checkoutDetails?.total;

export const getTenderPayload = checkoutDetails => {
  const paymentPayload = {
    type: checkoutDetails?.tender?.name,
    amount: getTotalAmount(checkoutDetails),
  };

  if (
    checkoutDetails?.paymentProvider !== PAYMENT_GATEWAYS.PAY_GATE &&
    checkoutDetails?.paymentProvider !== PAYMENT_GATEWAYS.THAILAND_PAYMENTS
  ) {
    paymentPayload.payload = checkoutDetails?.tender?.payload;
  }

  if (
    checkoutDetails?.paymentProvider === PAYMENT_GATEWAYS?.JAPAN_PAYMENTS &&
    checkoutDetails?.tender?.name === PAYMENT_TENDER_TYPES?.DGFT_CREDIT_CARD
  ) {
    paymentPayload.payload = {
      details: {
        cardType: getCardType(checkoutDetails?.paymentCardDetails?.cardNumber?.replace(/ /g, '')),
        expirationMonth: checkoutDetails?.paymentCardDetails?.cardExpDate?.replace(/ /g, '')?.split('/')[0],
        expirationYear: checkoutDetails?.paymentCardDetails?.cardExpDate?.replace(/ /g, '')?.split('/')[1],
        lastFour: checkoutDetails?.paymentCardDetails?.cardNumber?.slice(-4),
      },
    };
  }
  return [paymentPayload];
};

export const getInstruction = checkoutDetails => {
  const { userOption, enableOrderNoteForCheckout, userInformation } = checkoutDetails;
  if (enableOrderNoteForCheckout) {
    return checkoutDetails?.instruction;
  } else {
    return getTernaryValue(
      userOption === CHECKOUT_CONSTANTS?.LEAVE_AT_MY_DOOR,
      CHECKOUT_CONSTANTS?.EMPTY_STRING,
      userInformation?.value,
    );
  }
};

export const getDeliveryOption = checkoutDetails => {
  const isDeliveryOptionTranslationEnabled = checkoutDetails?.isDeliveryOptionTranslationEnabled;
  if (isDeliveryOptionTranslationEnabled) {
    return checkoutDetails?.userOption === CHECKOUT_CONSTANTS.HAND_IT_TO_ME
      ? translateWithI18Next(TRANSLATE_MAPPING_KEY?.HAND_IT_TO_ME)
      : translateWithI18Next(TRANSLATE_MAPPING_KEY?.LEAVE_AT_MY_DOOR);
  } else {
    return checkoutDetails?.userOption === CHECKOUT_CONSTANTS.HAND_IT_TO_ME
      ? checkoutUserOptionPayload?.HAND_IT_TO_ME
      : checkoutUserOptionPayload?.LEAVE_AT_MY_DOOR;
  }
};
const getDeliveryOrderRequest = (request, checkoutDetails) => {
  const { enableOrderNoteForCheckout, instruction, userInformation } = checkoutDetails;
  request.isContactless = checkoutDetails?.userOption === CHECKOUT_CONSTANTS?.LEAVE_AT_MY_DOOR;
  if (isSupportedTenantCode()) {
    request.deliveryPartners = checkoutDetails?.enableRetryCountForPickupOrderTracking
      ? checkoutDetails?.deliveryPartners
      : 'doordash'; // --- AU
    if (checkoutDetails?.isDriverTippingEnabled) {
      request.DriverTippingCharge = checkoutDetails?.driverdeliveryTip?.toFixed(0);
    }
    request.addressLines =
      checkoutDetails?.address2 !== '' &&
      !checkoutDetails?.enableAddressLocalisation &&
      checkoutDetails?.countryCode !== COUNTRY_CODE.JP
        ? [checkoutDetails?.address2]
        : [];
    request.instruction = enableOrderNoteForCheckout ? instruction : userInformation?.value;
    request.checkInSummary = checkoutDetails?.checkInSummary;
    request.deliveryOption = getDeliveryOption(checkoutDetails);
  } else if (checkoutDetails?.address2 !== '') {
    request.addressLines = [checkoutDetails?.address2];
    request.instruction = getInstruction(checkoutDetails);
  }
  executeAnalyticsForInStore(DISPOSITION_KEYS.DELIVERY, 'NA');
};

const getPickupOrderRequest = (request, checkoutDetails) => {
  const {
    placeOrderRequest,
    enableRetryCountForPickupOrderTracking,
    deliveryPartners,
    enableClickCollectScheduleOrder,
  } = checkoutDetails;
  if (checkoutDetails?.availableCollectionPoints) {
    placeOrderRequest.collectionPoint = {
      name: getTernaryValue(
        checkoutDetails?.userOption === CHECKOUT_CONSTANTS?.LEAVE_AT_MY_DOOR,
        CHECKOUT_CONSTANTS?.IN_STORE,
        CHECKOUT_CONSTANTS?.CURBSIDE,
      ),
      bayNumber: 0,
    };
  }
  if (enableRetryCountForPickupOrderTracking && !enableClickCollectScheduleOrder) {
    request.deliveryPartners = deliveryPartners; //CA
  }
  executeAnalyticsForInStore(DISPOSITION_KEYS.PICKUP, checkoutDetails?.userOption);
  request.instruction = getInstruction(checkoutDetails);
};

const getDineInOrderRequest = (request, checkoutDetails) => {
  const { placeOrderRequest } = checkoutDetails;
  placeOrderRequest.collectionPoint = {
    name: CHECKOUT_CONSTANTS?.IN_STORE,
    bayNumber: 0,
  };
  executeAnalyticsForInStore(
    DISPOSITION_KEYS?.DINE_IN.replace(CHECKOUT_CONSTANTS?.DASH, CHECKOUT_CONSTANTS?.EMPTY_STRING),
    placeOrderRequest.collectionPoint.name,
  );
};

const getCateringOrderRequest = (request, checkoutDetails) => {
  if (isCateringSomeOneElseContactDetailsCheck(checkoutDetails)) {
    const { cateringSomeOneElseContactDetails } = checkoutDetails;
    request.secondaryContact = {
      firstName: cateringSomeOneElseContactDetails?.userFirstName?.value,
      lastName: cateringSomeOneElseContactDetails?.userLastName?.value,
      email: cateringSomeOneElseContactDetails?.userEmail?.value,
      phoneNumber: cateringSomeOneElseContactDetails?.userPhone?.value,
    };
  }
};
export const isStorePartialOrFullyClosed = (checkoutDetails, currentStoreInfoData) => {
  const isScheduledTimePassedOrStoreClosedErr = isStoreClosedOrScheduledTimePassed(
    checkoutDetails,
    currentStoreInfoData,
  );
  const isStoreClosedErr = Boolean(currentStoreInfoData?.summary?.status?.currentStatus !== OPEN);
  return { isScheduledTimePassedOrStoreClosedErr, isStoreClosedErr };
};
export const isStoreClosedOrScheduledTimePassed = (checkoutDetails, currentStoreInfoData) => {
  const {
    date,
    epochTime,
    cartData,
    enableClickCollectScheduleOrderFlag,
    enableScheduleOrder,
    enableScheduleOrderFlag,
    enableClickCollectScheduleOrder,
  } = checkoutDetails;
  const isStoreOpen = Boolean(currentStoreInfoData?.summary?.status?.currentStatus === OPEN);
  const isTemproryClosed = isStoreTemproryClosed(cartData, currentStoreInfoData?.summary);
  const storeTimeZone = currentStoreInfoData?.summary?.timeZoneIANA;
  const promiseTime = currentStoreInfoData?.summary?.timings?.asap?.promiseTime;
  const isScheduleTimePassed = checkForScheduledTime({
    enableScheduleOrder,
    enableClickCollectScheduleOrder,
    timeZone: storeTimeZone,
    storeSelectedDate: date,
    storeSelectedTime: epochTime,
    promiseTime,
  });
  return enableScheduleOrderFlag
    ? isScheduleTimePassed ||
        (enableScheduleOrder && !isStoreOpen) ||
        (enableClickCollectScheduleOrderFlag && !isStoreOpen && isTemproryClosed)
    : false;
};
export const placeOrderClickHandler = (checkoutDetails, updateCheckoutDetails) => {
  const { dispatch, placeOrderRequest, userSubscription } = checkoutDetails;

  resetOrderReducer(checkoutDetails);
  dispatch(resetPaymentStatus());
  const request = getOrderDefaultRequest(checkoutDetails);
  if (userSubscription?.value) {
    request.marketingPreferences = [EMAIL, PHONE];
  }

  switch (safeUpperCase(checkoutDetails?.service)) {
    case DISPOSITION_KEYS?.DELIVERY:
      getDeliveryOrderRequest(request, checkoutDetails);
      break;
    case DISPOSITION_KEYS?.PICKUP:
      getPickupOrderRequest(request, checkoutDetails);
      break;
    case DISPOSITION_KEYS?.DINE_IN:
      getDineInOrderRequest(request, checkoutDetails);
      break;
    case DISPOSITION_KEYS?.CATERING:
      getCateringOrderRequest(request, checkoutDetails);
      break;
    // no default
  }

  updateCheckoutDetails({
    displayErrorMessage: false,
    showErrorPayment: false,
    showPaymentError: false,
    showPaymentPreValidationError: false,
    placeOrderRequest: { ...placeOrderRequest, request },
    paymentEnabled: !ChannelManager.isBrowser,
    isUpdatedOrderActionRequest: true,
    showPlaceOrderLoading: true,
  });
};

export const paymentErrorHandler = (paramObj, checkoutDetails, updateCheckoutDetails) => {
  if (
    paramObj &&
    paramObj.errorCode === CHECKOUT_CONSTANTS?.PAY_TM_MERCHANT_ID_ERROR &&
    isEmptyObject(checkoutDetails)
  ) {
    updateCheckoutDetails({
      isClickContinuePayment: false,
      paymentEnabled: true,
      showPaymentPreValidationError: true,
    });
  }
};

export const validateUserDetails = (checkoutDetails, updateCheckoutDetails) => {
  if (!checkoutDetails?.paymentEnabled) {
    const { userFullName, userFirstName, userLastName, userPhone, userEmail, userInformation } = checkoutDetails;

    updateDispositionOptions(
      {
        target: {
          value: userInformation?.value,
          name: TRANSLATE_MAPPING_KEY?.VEHICAL_INFORMATION,
        },
      },
      checkoutDetails,
      updateCheckoutDetails,
    );

    if (checkoutDetails?.isEnableFullName) {
      updateGustUserContactDetails(
        {
          target: { value: userFullName?.value, name: TRANSLATE_MAPPING_KEY?.FULL_NAME, checked: false },
        },
        checkoutDetails,
        updateCheckoutDetails,
      );
    } else {
      updateGustUserContactDetails(
        {
          target: { value: userFirstName?.value, name: TRANSLATE_MAPPING_KEY?.FIRST_NAME_LABEL, checked: false },
        },
        checkoutDetails,
        updateCheckoutDetails,
      );
      updateGustUserContactDetails(
        {
          target: { value: userLastName?.value, name: TRANSLATE_MAPPING_KEY?.LAST_NAME_LABEL, checked: false },
        },
        checkoutDetails,
        updateCheckoutDetails,
      );
    }
    updateGustUserContactDetails(
      {
        target: { value: userPhone?.value, name: TRANSLATE_MAPPING_KEY?.PHONE, checked: false },
      },
      checkoutDetails,
      updateCheckoutDetails,
    );
    updateGustUserContactDetails(
      {
        target: { value: userEmail?.value, name: TRANSLATE_MAPPING_KEY?.EMAIL, checked: false },
      },
      checkoutDetails,
      updateCheckoutDetails,
    );

    if (isCateringSomeOneElseContactDetailsCheck(checkoutDetails)) {
      validCateringSomeOneElseContactDetails(checkoutDetails, updateCheckoutDetails);
    }
  }
};

export const updateDispositionOptions = (event, checkoutDetails, updateCheckoutDetails) => {
  const update = () => {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    const { userOption, userInformation } = checkoutDetails;
    switch (inputName) {
      case TRANSLATE_MAPPING_KEY?.VEHICAL_INFORMATION:
        return getUserInformation(userInformation, inputValue, checkoutDetails?.service, userOption);
      case TRANSLATE_MAPPING_KEY?.DELIVERY_INSTRUCTION:
        return getUserInformation(userInformation, inputValue, checkoutDetails?.service, userOption, event);
      // no default
    }
  };
  updateCheckoutDetails({ isClickContinuePayment: false, userInformation: update() });
};

export const getUserInformation = (userInformation, inputValue, service, userOption, event) => {
  const userInformationObj = { ...userInformation };
  userInformationObj.value = inputValue;
  if (isDeliveryDisposition(service)) {
    if (inputValue.length > 0) {
      userInformationObj.error = false;
      userInformationObj.errorMessage = '';
    } else {
      event.target.placeholder = getTernaryValue(
        isDeliveryDisposition(service),
        translateWithI18Next(TRANSLATE_MAPPING_KEY?.DELIVERY_INSTRUCTIONS),
        translateWithI18Next(TRANSLATE_MAPPING_KEY?.VEHICLE_INFORMATION),
      );
    }
  } else {
    if (!inputValue && isPickupDisposition(service) && userOption === CHECKOUT_CONSTANTS.HAND_IT_TO_ME) {
      userInformationObj.error = true;
      userInformationObj.errorMessage = translateWithI18Next(
        TRANSLATE_MAPPING_KEY?.PLEASE_ENTER_YOUR_VEHICAL_INFORMATION,
      );
    } else if (
      inputValue?.length < 2 &&
      isPickupDisposition(service) &&
      userOption === CHECKOUT_CONSTANTS.HAND_IT_TO_ME
    ) {
      userInformationObj.error = true;
      userInformationObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_VEHICAL_INFORMATION);
    } else {
      userInformationObj.error = false;
      userInformationObj.errorMessage = '';
    }
  }
  return userInformationObj;
};

const getValidateAndGetFieldValue = params => {
  const {
    inputName,
    inputValue,
    isChecked,
    checkoutDetails,
    userFullName,
    userFirstName,
    userLastName,
    userPhone,
    userEmail,
    userSubscription,
  } = params;

  switch (inputName) {
    case TRANSLATE_MAPPING_KEY?.FULL_NAME:
      return {
        userFullName: {
          ...validateAndGetFullNameValue({ userFullName, inputValue, checkoutDetails }),
        },
      };
    case TRANSLATE_MAPPING_KEY?.FIRST_NAME_LABEL:
      return {
        userFirstName: {
          ...validateAndGetFirstNameValue({ userFirstName, inputValue, checkoutDetails }),
        },
      };
    case TRANSLATE_MAPPING_KEY?.LAST_NAME_LABEL:
      return {
        userLastName: {
          ...validateAndGetLastNameValue({ userLastName, inputValue, checkoutDetails }),
        },
      };
    case TRANSLATE_MAPPING_KEY?.PHONE:
      return {
        userPhone: {
          ...validateAndGetPhoneNumberValue({ userPhone, inputValue, checkoutDetails }),
        },
      };
    case TRANSLATE_MAPPING_KEY?.EMAIL:
      return {
        userEmail: {
          ...validateAndGetEmailValue({ userEmail, inputValue, checkoutDetails }),
        },
      };
    case TRANSLATE_MAPPING_KEY?.SUBSCRIPTION:
      return {
        userSubscription: {
          ...getSubscribeData(userSubscription, isChecked),
        },
      };
    // no default
  }
};

export const updateGustUserContactDetails = (event, checkoutDetails, updateCheckoutDetails) => {
  const update = () => {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    const isChecked = event.target.checked;

    const { userFullName, userFirstName, userLastName, userPhone, userEmail, userSubscription } = checkoutDetails;
    return getValidateAndGetFieldValue({
      inputName,
      inputValue,
      isChecked,
      checkoutDetails,
      userFullName,
      userFirstName,
      userLastName,
      userPhone,
      userEmail,
      userSubscription,
    });
  };
  updateCheckoutDetails({ isClickContinuePayment: false, ...update() });
};

export const validateAndGetFullNameValue = params => {
  const { userFullName, inputValue, checkoutDetails } = params;
  const { minLengthFullName, maxLengthFullName, validateRestrictedWords } = checkoutDetails;

  let fullNameObj = { ...userFullName };
  fullNameObj.value = inputValue;

  if (!inputValue || inputValue.length < minLengthFullName) {
    fullNameObj.error = true;
    fullNameObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.EMPTY_FULL_NAME_ERR_MSG);
  } else if (
    !isValidFullName(inputValue) ||
    inputValue.length > maxLengthFullName ||
    (validateRestrictedWords && isValueContainsRestrictionWords(inputValue))
  ) {
    fullNameObj.error = true;
    fullNameObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_FULL_NAME_ERR_MSG);
  } else {
    fullNameObj.error = false;
    fullNameObj.errorMessage = '';
  }
  return fullNameObj;
};

export const validateAndGetFirstNameValue = params => {
  const { userFirstName, inputValue, checkoutDetails } = params;
  const { minLengthFirstName, maxLengthFirstName, validateRestrictedWords } = checkoutDetails;

  const firstNameObj = { ...userFirstName };
  firstNameObj.value = inputValue;

  if (!inputValue || inputValue.length < minLengthFirstName) {
    firstNameObj.error = true;
    firstNameObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.EMPTY_FIRST_NAME_ERR_MSG);
  } else if (
    !isValidFullName(inputValue) ||
    inputValue.length > maxLengthFirstName ||
    (validateRestrictedWords && isValueContainsRestrictionWords(inputValue))
  ) {
    firstNameObj.error = true;
    firstNameObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_FIRST_NAME_ERR_MSG);
  } else if (inputValue.includes(' ')) {
    firstNameObj.error = true;
    firstNameObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.SPACE_FIRST_NAME_ERR_MSG);
  } else {
    firstNameObj.error = false;
    firstNameObj.errorMessage = '';
  }
  return firstNameObj;
};

export const validateAndGetLastNameValue = params => {
  const { userLastName, inputValue, checkoutDetails } = params;
  const { minLengthLastName, maxLengthLastName, validateRestrictedWords } = checkoutDetails;

  const lastNameObj = { ...userLastName };
  lastNameObj.value = inputValue;

  if (!inputValue || inputValue.length < minLengthLastName) {
    lastNameObj.error = true;
    lastNameObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.EMPTY_LAST_NAME_ERR_MSG);
  } else if (
    !isValidFullName(inputValue) ||
    inputValue.length > maxLengthLastName ||
    (validateRestrictedWords && isValueContainsRestrictionWords(inputValue))
  ) {
    lastNameObj.error = true;
    lastNameObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_LAST_NAME_ERR_MSG);
  } else if (inputValue.includes(' ')) {
    lastNameObj.error = true;
    lastNameObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.SPACE_LAST_NAME_ERR_MSG);
  } else {
    lastNameObj.error = false;
    lastNameObj.errorMessage = '';
  }
  return lastNameObj;
};

export const validateAndGetPhoneNumberValue = params => {
  const { userPhone, inputValue, checkoutDetails } = params;
  const { minLengthPhoneNumber, maxLengthPhoneNumber, phoneNumberRegExp } = checkoutDetails;

  let phoneObj = { ...userPhone };
  phoneObj.value = inputValue;
  if (!inputValue) {
    phoneObj.error = true;
    phoneObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.EMPTY_PHONE_NUMBER_ERR_MSG);
  } else if (
    inputValue.length < minLengthPhoneNumber ||
    inputValue.length > maxLengthPhoneNumber ||
    isNaN(inputValue) ||
    !phoneNumberRegExp.test(inputValue)
  ) {
    phoneObj.error = true;
    phoneObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_PHONE_NUMBER_ERR_MSG);
  } else {
    phoneObj.error = false;
    phoneObj.errorMessage = '';
  }
  return phoneObj;
};

export const validateAndGetEmailValue = params => {
  const { userEmail, inputValue, checkoutDetails } = params;
  const { maxLengthEmail } = checkoutDetails;

  let emailObj = { ...userEmail };
  emailObj.value = inputValue;

  if (!inputValue) {
    emailObj.error = true;
    emailObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.EMPTY_EMAIL_ERR_MSG);
  } else if (!isValidEmailFormat(inputValue) || inputValue.length > maxLengthEmail) {
    emailObj.error = true;
    emailObj.errorMessage = translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_EMAIL_ERR_MSG);
  } else {
    emailObj.error = false;
    emailObj.errorMessage = '';
  }
  return emailObj;
};

export const getSubscribeData = (subscribeObj, checkStatus) => {
  let subObj = { ...subscribeObj };
  subObj.value = checkStatus;
  subObj.error = !checkStatus;
  return subObj;
};

export const updateDefaultUserContactDetails = (userContactDetails, checkoutDetails, updateCheckoutDetails) => {
  const updateDetails = details => {
    const { userFullName, userFirstName, userLastName, userPhone, userEmail } = checkoutDetails;
    updateCheckoutDetails({
      customerId: userContactDetails?.id,
      userFullName: {
        ...userFullName,
        value: details?.fullName || details?.name,
      },
      userFirstName: {
        ...userFirstName,
        value: details?.firstName || details?.name,
      },
      userLastName: {
        ...userLastName,
        value: details?.lastName || details?.name,
      },
      userPhone: {
        ...userPhone,
        value: details?.phone,
      },
      userEmail: {
        ...userEmail,
        value: details?.email,
      },
    });
  };

  if (ChannelManager.isBrowser) {
    const customerData = getUserContactDetails(userContactDetails);
    updateDetails(customerData);
  } else if (ChannelManager.isPhonePe) {
    updateDetails(ChannelManager);
  }
};

export const getUserContactDetails = customerObj => {
  const { firstName = '', middleName = '', lastName = '', email = '', phone = '', phoneNumber = '' } = customerObj;

  return {
    ...customerObj,
    fullName: `${getTernaryValue(firstName, firstName, '')} ${getTernaryValue(
      middleName,
      middleName,
      '',
    )} ${getTernaryValue(lastName, lastName, '')}`?.trim(),
    email,
    phone: getTernaryValue(phone !== '', phone, phoneNumber),
  };
};

/**
 * getAvailableCollectionPointsFromStore - takes all available collection point data for a selected store
 * and returns just the web pickup options
 * @param availableCollectionPoints {Object} available collection point data for selected store
 * @returns {Object} contains collection points available for a given store for web pickup
 */
export const getAvailableCollectionPointsFromStore = availableCollectionPoints => {
  const allAvailableCollectionPoints = availableCollectionPoints?.find(
    collectionPoint => collectionPoint.channel === ChannelManager.channel && collectionPoint.service === PICK_UP,
  );
  return allAvailableCollectionPoints?.collectionPoints || {};
};

/**
 * checkDefaultSelectedCollectionMethod - checks if only one available collection point and selects it for the user
 * @param availableCollectionPoints {Array} - array of collectionPoints available to the store
 * @return {{name: (*|string)}|null} returns collectionPoint object needed for pickup method radio ctas
 */
export const checkDefaultSelectedCollectionMethod = availableCollectionPoints => {
  if (availableCollectionPoints?.length === 1) {
    return {
      name: availableCollectionPoints[0]?.collectionPointName || '',
    };
  }
  return null;
};

export const validateCheckoutDetails = (checkoutDetails, updateCheckoutDetails) => {
  const {
    isEnableFullName,
    userInformation,
    userFullName,
    userFirstName,
    userLastName,
    userEmail,
    userPhone,
    cateringSomeOneElseContactDetails,
    guestCheckOutFieldsValidated,
    guestOtpCheckoutValidated,
    enableGuestOtpCheckout,
    displayDeliveryTipping,
    type,
    isCustomTipSelected,
    isEmailMandatoryField,
    isLastNameMandatoryField,
  } = checkoutDetails;

  let userFieldValue = false;
  let userFieldError = false;

  if (isCateringSomeOneElseContactDetailsCheck(checkoutDetails)) {
    userFieldValue = Boolean(
      cateringSomeOneElseContactDetails?.userFirstName?.value &&
        cateringSomeOneElseContactDetails?.userLastName?.value &&
        cateringSomeOneElseContactDetails?.userEmail?.value &&
        cateringSomeOneElseContactDetails?.userPhone?.value,
    );
  } else if (isEnableFullName) {
    userFieldValue = Boolean(
      userFullName?.value &&
        (isEmailMandatoryField ? userEmail?.value : true) &&
        userPhone?.value &&
        (enableGuestOtpCheckout
          ? guestCheckOutFieldsValidated && guestOtpCheckoutValidated
          : guestCheckOutFieldsValidated),
    );
  } else if (displayDeliveryTipping && isDeliveryDisposition(type)) {
    userFieldValue = Boolean(
      userFirstName?.value &&
        userLastName?.value &&
        userEmail?.value &&
        userPhone?.value &&
        guestCheckOutFieldsValidated &&
        isCustomTipSelected,
    );
  } else {
    userFieldValue = Boolean(
      userFirstName?.value &&
        (isLastNameMandatoryField ? userLastName?.value : true) &&
        (isEmailMandatoryField ? userEmail?.value : true) &&
        userPhone?.value &&
        (enableGuestOtpCheckout
          ? guestCheckOutFieldsValidated && guestOtpCheckoutValidated
          : guestCheckOutFieldsValidated),
    );
  }

  if (isCateringSomeOneElseContactDetailsCheck(checkoutDetails)) {
    userFieldError = Boolean(
      cateringSomeOneElseContactDetails?.userFirstName?.error ||
        cateringSomeOneElseContactDetails?.userLastName?.error ||
        cateringSomeOneElseContactDetails?.userEmail?.error ||
        cateringSomeOneElseContactDetails?.userPhone?.error,
    );
  } else if (isEnableFullName) {
    userFieldError = Boolean(userInformation?.error || userFullName?.error || userEmail?.error || userPhone?.error);
  } else {
    userFieldError = Boolean(
      userInformation?.error || userFirstName?.error || userLastName?.error || userEmail?.error || userPhone?.error,
    );
  }

  const paymentEnabled = userFieldValue && !userFieldError && getEnablePaymentCtaForPickupMethod(checkoutDetails);
  if (
    isCateringSomeOneElseContactDetailsCheck(checkoutDetails) &&
    checkoutDetails?.isCateringSomeOneElseContactDetailsAreValid !== paymentEnabled
  ) {
    updateCheckoutDetails({ isCateringSomeOneElseContactDetailsAreValid: paymentEnabled });
  } else if (checkoutDetails?.paymentEnabled !== paymentEnabled) {
    updateCheckoutDetails({ paymentEnabled, isContactDetailValid: paymentEnabled });
  }
};

export const updateCheckoutContactAnalytics = (cartObject, type, storeObject, categoryMapByUrl, userType = '') => {
  const { currencyConversionFactor, currencyShortName = '' } = storeObject;
  if (cartObject?.foodLines?.length) {
    const itemsArray = [];
    const itemsArrayStep1 = [];
    const { discountTotal } = getCouponDetails(cartObject);
    const updated_discount = +(discountTotal / currencyConversionFactor).toFixed(2);
    cartObject?.foodLines.forEach((i, idx) => {
      let { baseItemsCstmz, saucesCstmz, sidesCstmz, drinksCstmz, customizedPrice } = foodlineProducts(i);
      baseItemsCstmz = itemFilter(baseItemsCstmz);
      saucesCstmz = itemFilter(saucesCstmz);
      sidesCstmz = itemFilter(sidesCstmz);
      drinksCstmz = itemFilter(drinksCstmz);
      let priceAmt = calculatePrice(i.amount, currencyConversionFactor);
      const { id = '', name = '' } = categoryMapByUrl?.[i?.item?.categoryUrl] ?? {};
      const itemObj = {
        item_name: i.item?.dname[0].value || 'NA',
        item_id: i.item?.id || 'NA',
        price: parseFloat(priceAmt) || 'NA',
        item_category: name,
        item_list_name: name,
        item_list_id: id,
        index: idx,
        quantity: i.quantity || 'NA',
        originalPrice: (customizedPrice / currencyConversionFactor).toString(),
        baseItemsCustomisation: baseItemsCstmz || '',
        saucesCustomisation: saucesCstmz || '',
        sidesCustomisation: sidesCstmz || '',
        drinksCustomisation: drinksCstmz || '',
      };
      itemsArray.push({ ...itemObj, currency: currencyShortName });
      itemsArrayStep1.push({
        ...itemObj,
        coupon: cartObject?.discountLines?.[0]?.promoCode ?? NOT_AVAILABLE,
        currency: currencyShortName,
        discount: updated_discount,
        item_variant: !i?.item?.modGrps?.length
          ? 'NA'
          : i?.item?.modGrps?.reduce((acc, cv) => acc + `${cv?.name}, `, ''),
      });
    });

    if (type === CHECKOUT_CONSTANTS?.ADD_CONTACT_INFO_CLICK) {
      addContactInfoClickAnalytics(itemsArray);
    }
    if (type === CHECKOUT_CONSTANTS?.BEGIN_CHECKOUT_CLICK) {
      const analyticsObject = {
        disposition: cartObject?.service,
        store_id: cartObject?.store?.storeId,
        coupon: cartObject?.discountLines?.[0]?.promoCode ?? NOT_AVAILABLE,
        total: +(cartObject?.total / currencyConversionFactor).toFixed(2),
        currency: currencyShortName,
      };
      beginCheckoutClickAnalyticsStep1(itemsArrayStep1, userType, analyticsObject);
    }
  }
};

export const getUserSubscriptionPanel = params => {
  const { checkoutDetails, updateCheckoutDetails } = params;
  return (
    <div className='d-flex mt-20px mb-20px'>
      <input
        tabIndex='0'
        data-testid='user-subscription-input'
        id={TRANSLATE_MAPPING_KEY?.SUBSCRIPTION}
        type='checkbox'
        name={TRANSLATE_MAPPING_KEY?.SUBSCRIPTION}
        className='common_checkbox mt-10px'
        value={checkoutDetails?.userSubscription?.value}
        onChange={event => updateGustUserContactDetails(event, checkoutDetails, updateCheckoutDetails)}
        checked={checkoutDetails?.userSubscription?.value}
      />
      <label htmlFor={TRANSLATE_MAPPING_KEY?.SUBSCRIPTION} title className='ml-10px form-check-label'>
        {
          <span data-testid='agree-txt' className='agreeTxt'>
            {translateWithI18Next(TRANSLATE_MAPPING_KEY?.AGREEMENT_LABEL)}
          </span>
        }
      </label>
    </div>
  );
};

export const updateCheckoutDetailsWithAppStateReducer = (appStateReducer, updateCheckoutDetails) => {
  const userParams = appStateReducer?.userStatus;
  updateCheckoutDetails({ userParams, showPreValidationPaymentContactError: !userParams?.loggedIn });
};

export const updateCheckoutDetailsWithUserOrderState = (
  userOrderState,
  userOrderStateDispatch,
  updateCheckoutDetails,
  checkoutDescDetails,
) => {
  if (isEmptyObject(userOrderState)) {
    const dispositionNew = getTernaryValue(
      isEmptyEvery(userOrderState?.dispositionNew),
      JSON.parse(localStorage?.getItem(LOCAL_STORAGE_KEYS.DISPOSITION_NEW)),
      userOrderState?.dispositionNew,
    );
    const {
      store,
      date,
      time,
      deliveryAddressLine = '',
      epochTime,
    } = isEmptyObject(dispositionNew) ? dispositionNew : {};
    updateCheckoutDetails({
      isOneClickEnabled: store?.summary?.isOneClickEnabled,
      store,
      date,
      time,
      epochTime,
      address2: deliveryAddressLine,
      isPaymentTypeSelected:
        userOrderState?.isPaymentAdded ||
        checkoutDescDetails?.isOrderWithoutPayment ||
        checkoutDescDetails?.isClickPlaceOrder,
      scheduleModalOpen: userOrderState?.scheduleModalOpen,
      placeOrderButtonEnabled: userOrderState?.placeOrderButtonEnabled,
      userOrderStateDispatch,
      timeBasedCetegory: userOrderState.timeBasedCetegory,
      currentStoreInfo: userOrderState?.currentStoreInfo,
    });
  }
};
export const userOptionValueFunction = (
  checkoutDetails,
  isContactlessFromPastOrder,
  checkoutDispositionDefaultOption,
) =>
  checkoutDetails?.userParams?.loggedIn && isContactlessFromPastOrder && checkoutDetails?.defaultFlagForContactless
    ? CHECKOUT_CONSTANTS?.LEAVE_AT_MY_DOOR
    : checkoutDispositionDefaultOption;

export const updateCheckoutDetailsWithGuestOtpReducer = (guestOtpReducer, checkoutDetails, updateCheckoutDetails) => {
  !isEmpty(guestOtpReducer?.tokenResponse?.access_token) &&
    updateCheckoutDetails({
      guestOtpTokenResponse: guestOtpReducer?.tokenResponse?.access_token,
    });
  !isEmpty(guestOtpReducer?.generateOTPResponse?.otp_id) &&
    updateCheckoutDetails({
      guestOtpId: guestOtpReducer?.generateOTPResponse?.otp_id,
    });
  !isEmpty(guestOtpReducer?.validateOTPResponse?.status) &&
    updateCheckoutDetails({
      guestOtpValidationFailedAttemptsCount: guestOtpReducer?.validateOTPResponse?.failed_attempts,
      guestOtpValidationMessge: guestOtpReducer?.validateOTPResponse?.status,
    });
  isEmpty(guestOtpReducer?.tokenResponse) &&
    isEmpty(guestOtpReducer?.generateOTPResponse) &&
    isEmpty(guestOtpReducer?.validateOTPResponse) &&
    updateCheckoutDetails({ guestOtpValidationMessge: GUEST_OTP_VERIFICATION_FAILURE });
};
export const updateCheckoutDetailsWithProfileReducer = (
  profileReducer,
  checkoutDetails,
  updateCheckoutDetails,
  tenantReducer,
) => {
  const { checkout: { checkoutDispositionDefaultOption } = {} } = tenantReducer?.componentOptions?.web ?? {};
  const isContactlessFromPastOrder = !isEmpty(profileReducer.pastOrders)
    ? profileReducer?.pastOrders[0]?.isContactless
    : checkoutDetails?.isContactlessFromPastOrder;
  updateCheckoutDetails({
    savedCards: profileReducer?.savedCards ?? [],
    isContactlessFromPastOrder,
    userOption: userOptionValueFunction(checkoutDetails, isContactlessFromPastOrder, checkoutDispositionDefaultOption),
    lastUsedPaymentMethod: profileReducer?.lastUsedPaymentMethod,
    profile: profileReducer?.basic,
  });
};

export const updateCheckoutDetailsWithTenantReducer = (tenantReducer, updateCheckoutDetails) => {
  if (!isEmpty(tenantReducer)) {
    const { checkout: { phoneNumberRegExp, displayDeliveryTipping = false, nameOnCardRegex } = {} } =
      tenantReducer?.componentOptions ?? {};
    const { checkout: { checkoutDispositionFirstOption, checkoutDispositionDefaultOption } = {} } =
      tenantReducer?.componentOptions?.web ?? {};
    const { accountPage: { maxSavedCardLimit = 0, showSavedCardCvv = false, termsAndConditionsShow = true } = {} } =
      tenantReducer?.componentOptions?.web ?? {};
    updateCheckoutDetails({
      tenantId: tenantReducer?.tenantId,
      addHope: tenantReducer?.addHope,
      carryBag: tenantReducer?.carryBag,
      currencySymbol: tenantReducer?.currencySymbol,
      currencyConversionFactor: tenantReducer?.currencyConversionFactor,
      currencyFormat: tenantReducer?.currencyFormat,
      countryCode: tenantReducer?.countryCode?.[0]?.toUpperCase?.(),
      currencyCode: tenantReducer?.currencyShortName,
      secondarycontact: tenantReducer?.secondarycontact,
      mobilecontactlessdeliveryenabled: tenantReducer?.mobilecontactlessdeliveryenabled,
      ...tenantReducer?.componentOptions?.web?.global,
      phoneNumberRegExp,
      nameOnCardRegex,
      scheduleOrderDateFormat: tenantReducer?.scheduleOrderDateFormat,
      dateFormat: tenantReducer?.dateFormat,
      paymentProvider: !isSupportedTenantCode() && tenantReducer?.componentOptions?.payment?.paymentProvider,
      showTaxLines: tenantReducer?.componentOptions?.web?.cartPage?.showTaxLines,
      showTaxExcluded: tenantReducer?.componentOptions?.web?.checkout?.showTaxExcluded,
      defaultFlagForContactless: tenantReducer?.componentOptions?.web?.checkout?.enableUsingIsContactlessFromPastOrder,
      displayDeliveryTipping,
      isDriverTippingEnabled: displayDeliveryTipping,
      enableTipping: displayDeliveryTipping,
      showSignUpBtn: tenantReducer?.componentOptions?.web?.checkout?.showSignUpBtn,
      enableRetryCountForPickupOrderTracking:
        tenantReducer?.componentOptions?.web?.orderPages?.enableRetryCountForPickupOrderTracking,
      enableContinuePayment: tenantReducer?.componentOptions?.web?.checkout?.enableContinuePayment,
      showDisplayNotifyMessage: tenantReducer?.componentOptions?.web?.checkout?.showDisplayNotifyMessage,
      enablePickupOption: tenantReducer?.componentOptions?.web?.checkout?.enablePickupOption,
      enableCheckoutGuestSignInUser: tenantReducer?.componentOptions?.web?.checkout?.enableCheckoutGuestSignInUser,
      enableAddress2DetailsPanel: tenantReducer?.componentOptions?.web?.checkout?.enableAddress2DetailsPanel,
      enablePriceSummary: tenantReducer?.componentOptions?.web?.checkout?.enablePriceSummary,
      enableInValidErrorMessage: tenantReducer?.componentOptions?.web?.checkout?.enableInValidErrorMessage,
      updateCustomerInfoToPos: tenantReducer?.componentOptions?.web?.checkout?.updateCustomerInfoToPos,
      showAddToPayment: tenantReducer?.componentOptions?.web?.checkout?.showAddToPayment,
      enableValidateOrder: tenantReducer?.componentOptions?.web?.checkout?.enableValidateOrder,
      enablePickupMethodDuringCheckout: tenantReducer?.enablePickupMethodDuringCheckout,
      enableAddressLocalisation: tenantReducer?.enableAddressLocalisation,
      enableOrderCapacityManagement: tenantReducer?.enableOrderCapacityManagement,
      enableScheduleOrder: tenantReducer?.componentOptions?.web?.dispositionConfig?.enableScheduleOrder,
      enableClickCollectScheduleOrder:
        tenantReducer?.componentOptions?.web?.dispositionConfig?.enableClickCollectScheduleOrder,
      buffertimepmpickup: tenantReducer?.buffertimepmpickup,
      buffertimepmdelivery: tenantReducer?.buffertimepmdelivery,
      shouldCheckDropOffAddress: tenantReducer?.componentOptions?.web?.shouldCheckDropOffAddress,
      checkoutDispositionFirstOption,
      userOption: checkoutDispositionDefaultOption,
      showGiftCardOrVoucher: tenantReducer?.componentOptions?.web?.checkout?.showGiftCardOrVoucher,
      showPromos: tenantReducer?.componentOptions?.web?.checkout?.showPromos,
      allowScheduleOrderForDispositions:
        tenantReducer?.componentOptions?.web?.scheduledDelivery?.allowScheduleOrderForDispositions || [],
      paymentCardValidateFailed: tenantReducer?.componentOptions?.web?.payment?.paymentCardValidateFailed,
      maxSavedCardLimit,
      termsAndConditionsShow,
      showSavedCardCvv,
      countryCodeNumeric: tenantReducer?.countryCodeNumeric,
      passStreetNoToOrder: tenantReducer?.componentOptions?.web?.cartPage?.passStreetNoToOrder,
      showDeleteSavedCard: tenantReducer?.componentOptions?.web?.payment?.showDeleteSavedCard,
      phoneMarketingOpt: tenantReducer?.componentOptions?.checkout?.phoneMarketingOpt,
      enableKFCGiftCard: tenantReducer?.componentOptions?.web?.checkout?.enableKFCGiftCard,
      isLastNameMandatoryField: tenantReducer?.componentOptions?.web?.checkout?.isLastNameMandatoryField,
      postalCodeRegex: tenantReducer?.componentOptions?.web?.global?.postalCodeRegex,
      enableOrderNoteForCheckout: tenantReducer?.componentOptions?.checkout?.enableOrderNoteForCheckout,
      billingAddressLength: tenantReducer?.componentOptions?.web?.global?.billingAddressLength,
      enableGuestOtpCheckout: tenantReducer?.componentOptions?.checkout?.enableGuestOtpCheckout,
      showProfileDetailOnCheckout: tenantReducer?.showProfileDetailOnCheckout,
      showProfileEditOption: tenantReducer?.showProfileEditOption,
      countryCodePhoneNumber: tenantReducer?.componentOptions?.app?.checkout?.countrycode,
      isEmailMandatoryField: tenantReducer?.componentOptions?.web?.accountPage?.isEmailMandatoryField,
      enableCookieAcceptance: tenantReducer?.enableCookieAcceptance,
      numberOfDigitsTobeMasked: tenantReducer?.componentOptions?.checkout?.numberOfDigitsTobeMasked,
      maximumFailedOtpCount: tenantReducer?.componentOptions?.checkout?.maximumFailedOtpCount,
      maskedNumberOfDigit: tenantReducer?.componentOptions?.checkout?.maskedNumberOfDigit,
      paymentAgreementAndOrderFlag: tenantReducer?.paymentAgreementAndOrderFlag,
      guestCheckoutEmptyFieldErrorMessage:
        tenantReducer?.componentOptions?.checkout?.guestCheckoutEmptyFieldErrorMessage,
      isDeliveryOptionTranslationEnabled:
        tenantReducer?.componentOptions?.app?.checkout?.isDeliveryOptionTranslationEnabled,
      showErrorMessageForGuestCheckout: tenantReducer?.showErrorMessageForGuestCheckout,
      eventCode: tenantReducer?.event?.eventCode,
      addressLineTwoMaxLength: tenantReducer?.addressLineTwoMaxLength,
      paymentTypeSelectedForLastUsedPayment:
        tenantReducer?.componentOptions?.checkout?.paymentTypeSelectedForLastUsedPayment,
    });
  }
};

export const updateCheckoutDetailsWithPaymentsReducer = (paymentsReducer, updateCheckoutDetails) => {
  !isEmpty(paymentsReducer?.response) &&
    updateCheckoutDetails?.({
      paymentsReducerResponse: paymentsReducer?.response,
    });
  !isEmpty(paymentsReducer?.paymentRequestedResponse) &&
    updateCheckoutDetails?.({
      paymentRequestedResponse: paymentsReducer?.paymentRequestedResponse,
    });
};

export const updateCheckoutDetailsWithPaymentsContentReducer = (paymentsContentReducer, updateCheckoutDetails) =>
  !isEmpty(paymentsContentReducer?.tendersContent) &&
  updateCheckoutDetails?.({ tendersContent: paymentsContentReducer?.tendersContent });

export const updateCheckoutDetailsWithCartData = props => {
  const {
    cartData,
    checkoutDetails,
    updateCheckoutDetails,
    showStateCityAddress,
    showFormattedAddressFromDisposition,
    dispositionText,
  } = props;
  if (!isEmpty(cartData)) {
    const allowedTenders = cartData?.allowedTenders ? cartData?.allowedTenders?.map(name => name?.toLowerCase()) : [];
    const availableCollectionPoints = cartData?.availableCollectionPoints || checkoutDetails?.availableCollectionPoints;
    const collectionPoint =
      checkDefaultSelectedCollectionMethod(availableCollectionPoints) || checkoutDetails?.collectionPoint;
    updateCheckoutDetails?.({
      basketId: cartData?.id,
      customerId: checkoutDetails?.customerId ?? cartData?.customer?.id,
      availableCollectionPoints,
      collectionPoint,
      allowedTenders,
      enablePayByCashOption: allowedTenders?.includes(PAYMENT_TENDER_TYPES.PAY_BY_CASH),
      cartDataCustomer: cartData?.customer,
      foodLines: cartData?.foodLines,
      deliveryAddress: getFullAddressDetails(cartData?.deliveryAddress, showStateCityAddress),
      isCartReducerLoadCount: checkoutDetails?.isCartReducerLoadCount + 1,
      formattedDeliveryAddress: showFormattedAddressFromDisposition
        ? dispositionText
        : getFullAddressDetails(cartData?.deliveryAddress, showStateCityAddress),
      type: cartData?.service,
      service: cartData?.service,
      total: cartData?.total,
      subTotal: cartData?.subtotal,
      taxAmounts: cartData?.inclusiveTaxTotal,
      additionalCharges: cartData?.additionalChargeLines ?? [],
      discountLines: cartData?.discountLines,
      discountTotal: cartData?.discountTotal,
      taxLines: cartData?.taxLines,
      cartSubTotal: cartData?.subtotal,
      cartTotal: cartData?.total,
      deliveryPartners: cartData?.deliveryPartners,
      requestedDateTime: cartData?.requestedDateTime,
      cartData,
    });
  }
};

const calcFinalAmount = params => {
  const { total, tenantReducer, updateCheckoutDetails } = params;

  const currencyChecks = Boolean(total && tenantReducer?.currencySymbol && tenantReducer?.currencyConversionFactor);
  updateCheckoutDetails({
    finalAmount: currencyChecks ? calculatePrice(total, tenantReducer?.currencyConversionFactor) : '',
  });
};

export const updateCheckoutDetailsWithCartDataTenantReducer = (cartData, tenantReducer, updateCheckoutDetails) => {
  calcFinalAmount({ total: cartData?.total, tenantReducer, updateCheckoutDetails });
};
export const updateCheckoutDetailsWithTenantBasedFlags = (cartData, tenantReducer, updateCheckoutDetails) => {
  const { dispositionConfig } = tenantReducer?.componentOptions?.web;
  const { enableScheduleOrder, enableClickCollectScheduleOrder } = dispositionConfig;
  const scheduledDelivery = tenantReducer?.componentOptions?.web?.scheduledDelivery;
  const { allowScheduleOrderForDispositions } = scheduledDelivery;
  const enableClickCollectScheduleOrderFlag =
    enableClickCollectScheduleOrder && allowScheduleOrderForDispositions.includes(cartData?.service);
  updateCheckoutDetails({
    enableScheduleOrderFlag: enableScheduleOrder || enableClickCollectScheduleOrderFlag,
    enableClickCollectScheduleOrderFlag,
  });
};
export const updateCheckoutDetailsWithOrderReducerTenantReducer = params => {
  const { orderReducer, tenantReducer, updateCheckoutDetails } = params;
  calcFinalAmount({ total: orderReducer?.response?.order?.total, tenantReducer, updateCheckoutDetails });
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const updateCheckoutDetailsWithOrderReducer = params => {
  const {
    isEvent,
    orderReducer,
    updateCheckoutDetails,
    checkoutDetails,
    isRetryPaymentErrorEnabled = false,
    tenantReducer,
  } = params;

  const isGuestCheckoutFlagEnabled =
    orderReducer?.response?.order?.isGuestCheckoutEnable === undefined
      ? tenantReducer?.componentOptions?.checkout?.guestCheckoutAllowed
      : orderReducer?.response?.order?.isGuestCheckoutEnable;

  const guestCheckoutAllowed = !isEvent && isGuestCheckoutFlagEnabled;

  !isEmpty(orderReducer?.response?.order) &&
    updateCheckoutDetails({
      orderId: orderReducer?.response?.order?.id,
      foodLines: orderReducer?.response?.order?.foodLines,
      total:
        orderReducer?.response?.order?.total +
        (orderReducer?.response?.pendingPayments?.length > 0 ? 0 : checkoutDetails?.driverdeliveryTip),
      subTotal: orderReducer?.response?.order?.subTotal,
      taxAmounts: orderReducer?.response?.order?.inclusiveTaxTotal,
      additionalCharges: orderReducer?.response?.order?.additionalChargeLines || [],
      discountLines: orderReducer?.response?.order?.discountLines ?? [],
      discountTotal: orderReducer?.response?.order?.discountTotal,
      paymentDiscountTotal: orderReducer?.response?.order?.paymentDiscountTotal,
      paymentDiscountLines: orderReducer?.response?.order?.paymentDiscountLines ?? [],
      taxLines: orderReducer?.response?.order?.taxLines,
      driverTippingChargeType: orderReducer?.response?.order?.driverTippingChargeType,
      driverTippingChargeOption: orderReducer?.response?.order?.driverTippingChargeOption,
      bottleDepositChargeTotal: orderReducer?.response?.order?.bottleDepositChargeTotal ?? 0,
      isOrderWithoutPayment: orderReducer?.response?.order?.isOrderWithoutPayment ?? false,
      driverTipDefaultSelected: orderReducer?.response?.order?.driverTippingChargeOption?.filter(
        tipping => tipping?.isDefault,
      ),
      driverTippingCharge: orderReducer?.response?.order?.driverTippingCharge,
      isRetryPaymentErrorEnabled,
      paymentProvider:
        orderReducer?.response?.order?.store?.paymentGateway ||
        tenantReducer?.componentOptions?.payment?.paymentProvider,
      merchantAccount: orderReducer?.response?.order?.store?.merchantAccount,
      guestCheckoutAllowed,
    });
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const updateCheckoutDetailsWithMenuReducer = (menuReducer, updateCheckoutDetails) => {
  if (isEmptyObject(menuReducer)) {
    const { flatResponse = [] } = menuReducer || {};
    const { categories = [] } = menuReducer?.data || {};
    const { categoryMapByUrl = {} } = getValueOrDefaultObject(flatResponse);
    updateCheckoutDetails({
      categories,
      categoryMapByUrl,
    });
  }
};
/*********************************************************************
 *
 *
 *********************************************************************/
export const checkoutContactAnalytics = (
  cartDataObject,
  currencyConversionFactor,
  categoryMapByUrl,
  userType = '',
  discount = '',
  coupon = '',
) => {
  if (cartDataObject?.foodLines?.length) {
    const itemsArraysStep2 = [];
    const updated_discount_step2 = (discount / currencyConversionFactor).toFixed(2);
    cartDataObject?.foodLines?.forEach((i, idx) => {
      let price_Amt = calculatePrice(i?.amount, currencyConversionFactor);
      const { id = '', name = '' } = categoryMapByUrl?.[i?.item?.categoryUrl] ?? {};
      itemsArraysStep2.push({
        item_name: i?.item?.dname[0]?.value || 'NA',
        item_id: i?.item?.id || 'NA',
        price: parseFloat(price_Amt) || 'NA',
        coupon: coupon,
        currency: cartDataObject?.currencyCode,
        discount: parseFloat(updated_discount_step2),
        item_category: name,
        item_list_name: name,
        item_list_id: id,
        index: idx + 1,
        quantity: i?.quantity || 'NA',
      });
    });
    onCheckoutAnalytics(userType, itemsArraysStep2);
  }
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const setMarketingOptDetails = (formFormFeildsData, checkoutDetails) => {
  return (
    formFormFeildsData?.isValid &&
    has(checkoutDetails, SESSION_STORAGE_KEYS.MARKETING_OPT_VAL) &&
    sessionStorage.setItem(SESSION_STORAGE_KEYS.MARKETING_OPT_VAL, JSON.stringify(checkoutDetails?.marketingOptInVal))
  );
};

/*********************************************************************
 *
 *
 *********************************************************************/
export const checkoutDetailsDefaultValue = {
  formPayload: {},
  openPaymentPopup: false,
  createOrderRequest: {},
  dispatch: null,
  tenantId: '',
  addHope: '',
  carryBag: '',
  currencySymbol: '',
  currencyConversionFactor: '',
  countryCode: '',
  currencyCode: '',
  currencyFormat: '',
  minLengthFullName: 0,
  maxLengthFullName: 0,
  minLengthFirstName: 0,
  maxLengthFirstName: 0,
  minLengthLastName: 0,
  maxLengthLastName: 0,
  maxLengthEmail: 0,
  minLengthPhoneNumber: 0,
  maxLengthPhoneNumber: 0,
  validateRestrictedWords: false,
  secondarycontact: null,
  mobilecontactlessdeliveryenabled: true,
  phoneNumberRegExp: '',
  nameOnCardRegex: '',

  service: '',
  type: '',
  store: '',
  date: '',
  time: '',
  address2: '',

  userParams: {},
  isMobile: isMobileDevice(window.innerWidth),

  basketId: '',
  customerId: null,
  finalAmount: '',
  availableCollectionPoints: null,
  collectionPoint: null,
  allowedTenders: [],
  cartDataCustomer: {},
  deliveryAddress: '',
  formattedDeliveryAddress: '',
  openOtpModal: false,
  isOtpMatch: true,

  isContactlessFromPastOrder: true,
  userOption: CHECKOUT_CONSTANTS?.LEAVE_AT_MY_DOOR,
  userInformation: { ...CHECKOUT_CONSTANTS?.FORM_FIELD_DEFAULT_VALUE },

  userFullName: { ...CHECKOUT_CONSTANTS?.FORM_FIELD_DEFAULT_VALUE, value: ChannelManager?.name ?? '' },
  userFirstName: { ...CHECKOUT_CONSTANTS?.FORM_FIELD_DEFAULT_VALUE, value: ChannelManager?.name ?? '' },
  userLastName: { ...CHECKOUT_CONSTANTS?.FORM_FIELD_DEFAULT_VALUE, value: ChannelManager?.name ?? '' },
  userPhone: { ...CHECKOUT_CONSTANTS?.FORM_FIELD_DEFAULT_VALUE, value: ChannelManager?.phone ?? '' },
  userEmail: { ...CHECKOUT_CONSTANTS?.FORM_FIELD_DEFAULT_VALUE, value: ChannelManager?.email ?? '' },
  userSubscription: { ...CHECKOUT_CONSTANTS?.FORM_FIELD_DEFAULT_VALUE, value: true },
  isEnableGustUser: false,
  isEnableFullName: false,

  foodLines: [],
  total: null,
  subTotal: null,
  taxAmounts: null,
  taxLines: [],
  showTaxLines: false,
  additionalCharges: null,
  discountLines: null,
  //openGuestPhoneNumberChangeModal:true,

  orderId: null,

  enablePickupMethodDuringCheckout: false,
  enableOrderNoteForCheckout: false,
  enableOrderCapacityManagement: false,
  recheckOrderCapacityData: false,
  recheckSpecialEventAvailability: false,
  shouldDisplayOrderCapacityError: false,
  enableContinuePayment: false,
  updateCustomerInfoToPos: false,
  enableValidateOrder: false,
  showPlaceOrderLoading: false,
  isClickAddPayment: false,
  isPaymentTypeSelected: false,
  isClickContinuePayment: false,
  isClickPlaceOrder: false,
  dispatchOrderAction: false,
  paymentEnabled: false,
  isUpdatedOrderActionRequest: false,
  isOneClickEnabled: false,
  showPaymentError: false,
  showErrorPayment: false,
  showBillAddressValidationError: false,
  showPaymentPreValidationError: false,
  isUpdateCheckoutContactAnalytics: false,
  showPreValidationPaymentContactError: false,
  displayErrorMessage: false,
  isCreateOrderValidateCompleted: false,
  isPaymentTransationCompleted: false,
  isPaymentTransationDeclined: false,
  categoryMapByUrl: {},
  isCartReducerLoadCount: 0,
  enablePaymentProviderPlaceOrderBtn: false,
  enableSinglePayment: false,
  scheduleModalOpen: false,
  isTemproryClosed: false,
  isFutureDateTimeOrder: false,
  enableDispositionOptionInstruction: false,
  categories: [],
  isCateringSomeOneElseContactDetailsEnabled: false,
  cateringSomeOneElseContactDetails: {
    userFirstName: { ...CHECKOUT_CONSTANTS?.FORM_FIELD_DEFAULT_VALUE },
    userLastName: { ...CHECKOUT_CONSTANTS?.FORM_FIELD_DEFAULT_VALUE },
    userPhone: { ...CHECKOUT_CONSTANTS?.FORM_FIELD_DEFAULT_VALUE },
    userEmail: { ...CHECKOUT_CONSTANTS?.FORM_FIELD_DEFAULT_VALUE },
  },
  isCateringSomeOneElseContactDetailsAreValid: true,

  tender: { name: '', payload: {} },

  placeOrderRequest: {
    tenantId: null,
    customerId: null,
    request: {
      basketId: null,
      tenders: [],
      customer: {},
    },
  },
  termsPolicyCheckbox: false,
  marketingOptCheckbox: false,
  marketingOptInVal: {
    Email: false,
    Phone: false,
  },
  isContactDetailValid: false,
  driverdeliveryTip: 0,
  driverTipDisplayAmount: '',
  guestCheckOutFieldsValidated: false,
  isCustomTipSelected: true,
  enableRetryCountForPickupOrderTracking: false,
  deliveryPartners: '',
  enablePaymentCardInputPanel: false,
  paymentCardDetails: {
    isFormValid: false,
  },
  enablePayOnArrival: false,
  isClickPayOnArrival: false,
  enablePayByCashOption: false,
  payOnArrivalAllowedTenders: [],
  payNowAllowedTenders: [],
  timeBasedCetegory: {},
  storeClosesSoon: false,
  selectedFavDeliveryAddress: '',
  enableTipping: false,
  guestOtpTokenResponse: {},
  guestOtpValidationMessge: GUEST_OTP_VERIFICATION_SUCCESS,
  guestOtpValidationFailedAttemptsCount: 0,
  guestOtpId: {},
  guestOtpCheckoutValidated: false,
  RNAtoken: '',
  appchannel: '',
  billingAddressAndSaveCard: {
    Showcheckbox: true,
    addressLine: '',
    fulladdress: '',
    addressline1: '',
    addressline2: '',
    city: '',
    pincode: '',
    state: '',
    issavecard: false,
    nickName: '',
    isvalid: false,
    defaultAddressSelected: true,
    enabledSaveCard: false,
    location: {
      latitude: '',
      longitude: '',
    },
    isBillingAddressFormDetailsValid: false,
  },
  maxSavedCardLimit: 0,
  lastUsedPaymentMethod: '',
  showSavedCardCvv: false,
  cvvError: '',
  termsAndConditionsShow: true,
  savedCardCvv: '',
  countryCodeNumeric: '',
  useDifferentNumberFlow: false,
  useDifferentNumberFlowFlagForGuidanceMessage: false,
  enableGuestOtpCheckout: false,
  showCardNickNameError: '',
  streetNumberValue: '',
  getGiftCardBalance: 0,
  finalGiftCardValue: 0,
  validPhoneNumber: {
    isFormValid: false,
  },
  showPlaceOrderButton: true,
  countryCodePhoneNumber: '',
  numberOfDigitsTobeMasked: 0,
  maskedNumberOfDigit: '',
  maximumFailedOtpCount: 0,
  showGuidanceMessageForMaxAttemptCount: false,
  showGuidanceMessageForOtpExpiry: false,
  showMaxAttemptToast: false,
  isChangeButtonClicked: false,
  isEmailMandatoryField: false,
  isLastNameMandatoryField: false,
  showGuidanceForDifferentNumber: false,
  paymentAgreementAndOrderFlag: false,
  showProfileDetailOnCheckout: false,
};

export const getSearchParameters = search =>
  search &&
  search
    .replace(/\?/g, '')
    .split('&')
    .reduce((acc, cur) => {
      const [key, prop = ''] = cur?.split('=');
      acc[key] = decodeURIComponent(prop);
      return acc;
    }, {});

export const addRemoveInclass = isAddClass => {
  const ele = document.getElementsByClassName('home-loading-animation')?.[0]?.classList;
  isAddClass ? ele?.add('continue') : ele?.remove('continue');
};

export const shouldPaymentMethodEnabled = checkoutDetails => {
  return checkoutDetails?.userParams?.loggedIn || checkoutDetails?.guestCheckOutFieldsValidated;
};

export const updateCheckoutDetailsWithMarketConfigReducer = (marketConfigReducer, updateCheckoutDetails) => {
  if (!isEmpty(marketConfigReducer)) {
    updateCheckoutDetails({
      marketConfigReducer: marketConfigReducer?.response,
    });
  }
};

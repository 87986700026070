import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import Accordian from 'atoms/Accordian/Accordian';
import ImageComponent from 'atoms/Image';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import {
  DISPOSITION_KEYS,
  SHARED_CONSTANTS,
  IMAGE_SIZE_CONSTANTS,
  CHECKOUT_CONSTANTS,
} from 'common/constants/SharedConstants';
import { CART_PAGE_CONSTANTS } from 'organisms/CartPage/Constants/CartPageConstants';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import {
  displayPrice,
  errorStyleForMaterialTextInput,
  getAddHopeDataPrice,
  getAddHopeId,
  getCarryBagDataExistsId,
  getCarryBagDataPrice,
  getSumOfQuantity,
  getTernaryValue,
  isDeliveryDisposition,
  isPickupDisposition,
  safeUpperCase,
  getPriceDetails,
  getAndCheck,
  getUrlImagePath,
  getSumOfQuantityWithoutHopeBag,
  getFilteredItems,
} from 'common/utilities/utils';

export const getMaterialTextInputError = (param, imagePath = IMAGE_PATH.Ellipse) => {
  return (
    <div className={errorStyleForMaterialTextInput(param?.error)}>
      {param.error && <img src={imagePath} alt='Material Text Input Error' className={`mr-2`} />}
      <span role='alert'>{param.errorMessage}</span>
    </div>
  );
};

// Returns the Image Component
export const getImageComponent = (url, flagName, className = '', alt = '') => {
  return (
    <ImageComponent
      srcFile={getUrlImagePath(url, IMAGE_SIZE_CONSTANTS?.[flagName]?.WIDTH, IMAGE_SIZE_CONSTANTS?.[flagName]?.HEIGHT)}
      className={className}
      alt={alt}
    />
  );
};

export const displayNotifyMessage = notifyMessage => {
  return (
    notifyMessage && (
      <div className='cart-notification col-12'>
        <div className='notify-alert'>
          <ImageComponent srcFile={IMAGE_PATH.Error_Yellow} className='location-alert-icon' />
          <span>{notifyMessage}</span>
        </div>
      </div>
    )
  );
};

export const getModGrpsList = (subModifiers, currencySymbol, currencyConversionFactor) => {
  return (
    <li style={{ listStyleType: 'disc' }}>{`${subModifiers?.quantity}x ${
      subModifiers?.dname?.[0]?.value
    } ${getPriceDetails(
      subModifiers?.unitPrice,
      subModifiers?.quantity,
      currencySymbol,
      currencyConversionFactor,
    )}`}</li>
  );
};

export const getCustomisationList = (
  foodLineItemModifiers,
  currencySymbol,
  currencyConversionFactor,
  customizable,
  flag = false,
  taxAmountRoundOff,
) => {
  //console.log("comment to retrigger pipeline")
  return (
    foodLineItemModifiers && (
      <ul className='checkout-alignment-text'>
        {foodLineItemModifiers?.map(modifier => {
          if (modifier?.modifiers?.length) {
            return modifier?.modifiers?.map(subModifiers => {
              if (subModifiers?.modGrps?.length && !flag) {
                return (
                  <>
                    <li style={{ listStyleType: 'disc' }}>{`${subModifiers?.quantity}x ${
                      subModifiers?.dname?.[0]?.value
                    } ${getPriceDetails(
                      subModifiers?.unitPrice,
                      subModifiers?.quantity,
                      currencySymbol,
                      currencyConversionFactor,
                    )}`}</li>
                    {getCustomisationList(
                      subModifiers?.modGrps,
                      currencySymbol,
                      currencyConversionFactor,
                      customizable,
                      true,
                    )}
                  </>
                );
              } else if ((!flag && customizable) || subModifiers?.custom) {
                let isModal = flag || !customizable;
                return (
                  <li style={{ listStyleType: 'disc' }}>
                    {getAndCheck(!subModifiers?.isAdded && isModal, subModifiers?.custom)
                      ? `${translateWithI18Next(TRANSLATE_MAPPING_KEY.NO)} ${subModifiers?.dname?.[0]?.value} (-)`
                      : `${subModifiers?.quantity}x ${subModifiers?.dname?.[0]?.value} ${getPriceDetails(
                          subModifiers?.unitPrice,
                          subModifiers?.quantity,
                          currencySymbol,
                          currencyConversionFactor,
                          taxAmountRoundOff,
                        )}`}
                  </li>
                );
              }
              return null;
            });
          } else {
            return null;
          }
        })}
      </ul>
    )
  );
};

export const displayListOfCartItemInAccordion = params => {
  const {
    foodLines,
    currencySymbol,
    currencyConversionFactor,
    shortDescription = true,
    showasterik,
    taxLines,
    taxAmountRoundOff,
    showCarryBagInLineItem,
    addHope,
  } = params;
  const carryBag = translateWithI18Next(TRANSLATE_MAPPING_KEY?.LARGE_CARRY_BAG);
  const quantity = getSumOfQuantityWithoutHopeBag(foodLines, addHope, carryBag, showCarryBagInLineItem);
  const filterFoodLines = getFilteredItems(foodLines, addHope, carryBag, showCarryBagInLineItem);
  const reducedTaxTypeProductArray = taxLines?.filter(
    tax => tax.typeOfTax === TRANSLATE_MAPPING_KEY?.REDUCED_TAX_VALUE,
  );

  return (
    <Accordian
      header={`${quantity} ${getTernaryValue(
        quantity === 1,
        safeUpperCase(translateWithI18Next(TRANSLATE_MAPPING_KEY?.CART_ITEM)),
        safeUpperCase(translateWithI18Next(TRANSLATE_MAPPING_KEY?.CART_ITEMS)),
      )}`}
      cssClass='billDropDown'
      dataTestId='checkout-total-count'
    >
      {showasterik && reducedTaxTypeProductArray?.length && (
        <div className='reducedTaxRequiredText mb-20px' data-testid='view-asterik'>
          {CHECKOUT_CONSTANTS?.STAR}
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.REDUCED_TAX_ELIGIBLE_TEXT)}
        </div>
      )}
      {filterFoodLines?.map((data, index) => (
        <div key={index} className='billDescription col-12 p-0px-imp'>
          <div id='foodItemContainer' className='col-8 d-flex p-0px-imp'>
            {showasterik &&
              data?.taxComponents?.filter(
                ReducedTaxProduct => ReducedTaxProduct.taxType === TRANSLATE_MAPPING_KEY?.REDUCED_TAX_VALUE,
              ).length > 0 && (
                <div className='p-0px-imp'>
                  <span className='asterik'>{CHECKOUT_CONSTANTS?.STAR}</span>
                </div>
              )}
            <div className='col-2 p-0px-imp'>
              <span className='number'>{data?.quantity}</span>
            </div>
            <div className='col-10 p-0px-imp'>
              <span id='foodNameChild' className='foodName' data-testid='view-receipt-modal-item-name'>
                {' '}
                {data?.item?.dname?.[0]?.value || data?.item?.displayName}
              </span>
              {shortDescription && (
                <span id='foodNameChildSubDesc' className='foodName subDesc d-block'>
                  {getCustomisationList(
                    data.item.modGrps,
                    currencySymbol,
                    currencyConversionFactor,
                    data.item.customizable,
                    false,
                    taxAmountRoundOff,
                  )}
                </span>
              )}
            </div>
          </div>
          <div className='ItemPrice foodName col-4 p-0px-imp' data-testid='view-receipt-item-price'>
            {displayPrice?.call(
              this,
              data?.quantity * data?.unitPrice,
              {
                currencySymbol,
                currencyConversionFactor,
              },
              '',
              taxAmountRoundOff,
            )}{' '}
          </div>
        </div>
      ))}
    </Accordian>
  );
};

export const getCartSubTotalPanel = (subTotal, currencySymbol, currencyConversionFactor, taxAmountRoundOff) => {
  return (
    <div className='flexBetweens checkout-summary-content-item'>
      <div className='checkout-summary-content-item-name' data-testid='checkout-subtotal'>
        {translateWithI18Next(TRANSLATE_MAPPING_KEY?.CART_SUB_TOTAL)}
      </div>
      <div className='checkout-summary-content-item-value' data-testid='checkout-subtotal-price'>
        {displayPrice(subTotal, { currencySymbol, currencyConversionFactor }, '', taxAmountRoundOff)}
      </div>
    </div>
  );
};
export const getAddhopePanel = subTotal => {
  return (
    <div className='flexBetweens checkout-summary-content-item'>
      <div className='checkout-summary-content-item-name' data-testid='checkout-subtotal'>
        {translateWithI18Next(TRANSLATE_MAPPING_KEY?.ADD_HOPE)}
      </div>
      <div className='checkout-summary-content-item-value' data-testid='checkout-subtotal-price'>
        {subTotal}
      </div>
    </div>
  );
};

export const getCartDiscountTotalPanel = (discountTotal, currencySymbol, currencyConversionFactor) => {
  return (
    <div className='flexBetweens checkout-summary-content-item'>
      <div className='checkout-summary-content-item-name'>
        {translateWithI18Next(TRANSLATE_MAPPING_KEY?.DISCOUNT_TEXT)}
      </div>
      <div className='checkout-summary-content-item-value'>
        - {displayPrice(discountTotal, { currencySymbol, currencyConversionFactor })}
      </div>
    </div>
  );
};

export const getCartTotalPanel = (
  total,
  currencySymbol,
  currencyConversionFactor,
  keyName = TRANSLATE_MAPPING_KEY?.TOTAL,
  taxAmountRoundOff,
) => {
  return (
    <div className='flexBetweens checkout-summary-content-item'>
      <div className='checkout-summary-content-item-name-total' data-testid='checkout-total-amount-name'>
        {translateWithI18Next(keyName)?.toUpperCase()}:
      </div>
      <div className='checkout-summary-content-item-value-total' data-testid='checkout-total-amount-price'>
        {displayPrice(total, { currencySymbol, currencyConversionFactor }, '', taxAmountRoundOff)}
      </div>
    </div>
  );
};

export const getCartGSTPanel = (
  taxAmounts,
  currencySymbol,
  currencyConversionFactor,
  showTaxExcluded,
  taxAmountRoundOff,
) => {
  return (
    showTaxExcluded && (
      <div className='flexBetweens checkout-summary-content-item' data-testid='checkout-summary-taxslabs-section'>
        <div className='checkout-summary-content-item-name'>
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.CART_GST)}
        </div>
        <div className='checkout-summary-content-item-value'>
          {displayPrice(taxAmounts, { currencySymbol, currencyConversionFactor }, '0.00', taxAmountRoundOff)}
        </div>
      </div>
    )
  );
};

export const getAllTaxDetails = (
  taxLines,
  currencySymbol,
  currencyConversionFactor,
  pageName = 'checkout',
  taxAmountRoundOff = false,
) => {
  return taxLines?.map(item => (
    <div className={`flexBetweens ${pageName}`} data-testid={`${pageName}-summary-taxslabs-section`}>
      <div className={`${pageName}-summary-content-item-name`}>
        {translateWithI18Next(item.typeOfTax.replace(/\s/g, '').toLowerCase())} {item.taxPercentage}%
      </div>
      <div className={`${pageName}-summary-content-item-value`} data-testid='display-tax-price-id'>
        {displayPrice(item.amount, { currencySymbol, currencyConversionFactor }, '0.00', taxAmountRoundOff)}
      </div>
    </div>
  ));
};

const ReducedTaxAmounts = (taxLines, product) =>
  taxLines
    ?.filter(tax => tax.typeOfTax === TRANSLATE_MAPPING_KEY?.REDUCED_TAX_VALUE)
    .map(tax => (product ? tax.itemAmountOnTaxApplied : tax.amount));

export const getReducedTaxView = (
  taxLines,
  currencySymbol,
  currencyConversionFactor,
  pageName = 'checkout',
  taxAmountRoundOff,
) => {
  const ReducedTaxTitle = translateWithI18Next(TRANSLATE_MAPPING_KEY?.REDUCED_TAX);
  return (
    taxLines?.length && (
      <div className={`flexBetweens ${pageName}`} data-testid={`${pageName}-summary-taxslabs-section`}>
        <div className={`${pageName}-summary-content-item-name`}>
          {ReducedTaxTitle}
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.TAX_TEXT)}
        </div>
        <div className={`${pageName}-summary-content-item-value`} data-testid='display-tax-price-id'>
          {displayPrice(
            ReducedTaxAmounts(taxLines, true),
            { currencySymbol, currencyConversionFactor },
            '',
            taxAmountRoundOff,
          )}
          {taxAmountRoundOff}({translateWithI18Next(TRANSLATE_MAPPING_KEY?.TAX_TEXT)}{' '}
          {displayPrice(
            ReducedTaxAmounts(taxLines),
            { currencySymbol, currencyConversionFactor },
            '',
            taxAmountRoundOff,
          )}
          )
        </div>
      </div>
    )
  );
};

const StandardTaxAmount = (taxLines, product) =>
  taxLines
    ?.filter(tax => tax.typeOfTax === TRANSLATE_MAPPING_KEY?.STANDARD_TAX_VALUE)
    .map(tax => (product ? tax.itemAmountOnTaxApplied : tax.amount));

export const getStandardTaxView = (
  taxLines,
  currencySymbol,
  currencyConversionFactor,
  pageName = 'checkout',
  taxAmountRoundOff,
) => {
  const StandardTaxTitle = translateWithI18Next(TRANSLATE_MAPPING_KEY?.STANDARD_TAX);
  return (
    taxLines?.length && (
      <div className={`flexBetweens ${pageName}`} data-testid={`${pageName}-summary-taxslabs-section`}>
        <div className={`${pageName}-summary-content-item-name`}>
          {StandardTaxTitle}
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.TAX_TEXT)}
        </div>
        <div className={`${pageName}-summary-content-item-value`} data-testid='display-tax-price-id'>
          {displayPrice(
            StandardTaxAmount(taxLines, true),
            { currencySymbol, currencyConversionFactor },
            '',
            taxAmountRoundOff,
          )}
          ({translateWithI18Next(TRANSLATE_MAPPING_KEY?.TAX_TEXT)}{' '}
          {displayPrice(
            StandardTaxAmount(taxLines),
            { currencySymbol, currencyConversionFactor },
            '',
            taxAmountRoundOff,
          )}
          )
        </div>
      </div>
    )
  );
};

export const getAdditionalChargesPanel = (
  additionalCharges,
  currencySymbol,
  currencyConversionFactor,
  taxAmountRoundOff,
  service = DISPOSITION_KEYS?.DELIVERY,
  showCustomDeliveryFee = false,
) => {
  let indianCustomDeliveryFee = {};
  let filteredAdditionalCharges = [];

  if (additionalCharges?.length > 0) {
    filteredAdditionalCharges = additionalCharges.filter(item => item.chargeType !== 'in-delivery-charge');
  }

  if (showCustomDeliveryFee) {
    indianCustomDeliveryFee = additionalCharges.find(item => item.chargeType === 'in-delivery-charge');
    return (
      <>
        {filteredAdditionalCharges?.length > 0 &&
          filteredAdditionalCharges?.map((item, idx) => {
            return (
              <div key={idx} className='flexBetweens checkout-summary-content-item'>
                <div className='checkout-summary-content-item-name'>
                  {isDeliveryDisposition(service)
                    ? translateWithI18Next(TRANSLATE_MAPPING_KEY?.DELIVERY_FEE)
                    : translateWithI18Next(TRANSLATE_MAPPING_KEY?.RESTAURANT_HANDLING)}
                </div>
                <div className='checkout-summary-content-item-value'>
                  {displayPrice(item?.amount, { currencySymbol, currencyConversionFactor }, '', taxAmountRoundOff)}
                </div>
              </div>
            );
          })}

        {indianCustomDeliveryFee?.amount > 0 && (
          <div className='flexBetweens checkout-summary-content-item'>
            <div className='checkout-summary-content-item-name'>
              {isDeliveryDisposition(service)
                ? translateWithI18Next(TRANSLATE_MAPPING_KEY?.IN_DELIVERY_FEE)
                : translateWithI18Next(TRANSLATE_MAPPING_KEY?.RESTAURANT_HANDLING)}
            </div>
            <div className='checkout-summary-content-item-value' data-testid='display-delivery-fee-id'>
              {displayPrice(
                indianCustomDeliveryFee?.amount,
                { currencySymbol, currencyConversionFactor },
                '',
                taxAmountRoundOff,
              )}
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      filteredAdditionalCharges?.length > 0 &&
      filteredAdditionalCharges?.map((item, idx) => {
        return (
          <div key={idx} className='flexBetweens checkout-summary-content-item'>
            <div className='checkout-summary-content-item-name'>
              {isDeliveryDisposition(service)
                ? translateWithI18Next(TRANSLATE_MAPPING_KEY?.DELIVERY_FEE)
                : translateWithI18Next(TRANSLATE_MAPPING_KEY?.RESTAURANT_HANDLING)}
            </div>
            <div className='checkout-summary-content-item-value'>
              {displayPrice(item?.amount, { currencySymbol, currencyConversionFactor }, '', taxAmountRoundOff)}
            </div>
          </div>
        );
      })
    );
  }
};

export const getDeliveryTipPanel = (driverTipDisplayAmount, currencySymbol, currencyConversionFactor) => {
  return (
    <div className='flexBetweens checkout-summary-content-item'>
      <div className='checkout-summary-content-item-name'>
        {translateWithI18Next(TRANSLATE_MAPPING_KEY?.DRIVER_TIP)}
      </div>
      <div className='checkout-summary-content-item-value'>
        {displayPrice(driverTipDisplayAmount, { currencySymbol, currencyConversionFactor })}
      </div>
    </div>
  );
};
export const getDiscountPanelLabel = (item, showDiscountCouponCode, isPaymentDiscount, toolTip = false) => {
  const type =
    item?.type?.toLowerCase() === CART_PAGE_CONSTANTS.GIFTCARD
      ? CART_PAGE_CONSTANTS.GIFT_CARD
      : item?.type?.toLowerCase();
  const discountCode = isPaymentDiscount ? item?.paymentDiscountCode : item?.couponCode;
  const discountLabel = isPaymentDiscount
    ? discountCode
    : (discountCode?.length > 10 && !toolTip && discountCode?.substr(0, 10) + '...') || discountCode;
  const label = showDiscountCouponCode ? ` (${discountLabel})` : '';
  return isPaymentDiscount ? type + ' ' + label : translateWithI18Next(TRANSLATE_MAPPING_KEY?.DISCOUNT_TEXT) + label;
};

export const getDiscountAmountsPanelFlag = (amount, factors) => {
  const { currencySymbol, currencyConversionFactor } = factors;
  return currencySymbol && currencyConversionFactor && amount !== 0;
};

export const getDiscountAmountsPanel = params => {
  const {
    discountLines,
    currencySymbol,
    currencyConversionFactor,
    removeCouponHandler = null,
    showDiscountCouponCode = true,
    showDiscountRemoveIcon = true,
    isPaymentDiscount = false,
  } = params;
  return (
    discountLines?.length > 0 &&
    discountLines?.map((item, idx) => {
      const discountCode = isPaymentDiscount ? item?.paymentDiscountCode : item?.couponCode;
      return (
        getDiscountAmountsPanelFlag(item?.amount, {
          currencySymbol,
          currencyConversionFactor,
        }) && (
          <div key={idx} className='flexBetweens checkout-summary-content-item'>
            <div
              className={`${
                isPaymentDiscount ? 'checkout-summary-content-item-name-wicode' : 'checkout-summary-content-item-name'
              } d-flex`}
            >
              <span
                title={getDiscountPanelLabel(item, showDiscountCouponCode, isPaymentDiscount, true)}
                className={`checkout-discount-code ${
                  item?.status?.name?.toUpperCase() !== SHARED_CONSTANTS?.APPLIED ? 'text-strike-out' : ''
                }`}
              >
                {getDiscountPanelLabel(item, showDiscountCouponCode, isPaymentDiscount, false)}
              </span>
              {showDiscountRemoveIcon && (
                <span
                  className='checkout-discount-clear-icon'
                  data-testid={`remove-coupon-handler-${discountCode}`}
                  onClick={() => removeCouponHandler && removeCouponHandler(discountCode)}
                >
                  <img src={IMAGE_PATH?.CircleCloseButton} alt='' />
                </span>
              )}
            </div>
            <div
              className={`checkout-summary-content-item-value ${
                item?.status?.name?.toUpperCase() !== SHARED_CONSTANTS?.APPLIED ? ' text-strike-out' : ''
              }`}
            >
              {`-${displayPrice(
                item?.amount,
                {
                  currencySymbol,
                  currencyConversionFactor,
                },
                '0.00',
              )}`}
            </div>
          </div>
        )
      );
    })
  );
};

export const getCarryBagPanel = params => {
  const { serviceType, foodLines, carryBag, currencySymbol, currencyConversionFactor, showCarryBagInLineItem } = params;
  return (
    isPickupDisposition(serviceType) &&
    !showCarryBagInLineItem &&
    getCarryBagDataExistsId(foodLines, carryBag, currencyConversionFactor) && (
      <div className='flexBetweens checkout-summary-content-item' data-testid='checkout-carry-bag-text'>
        <div className='checkout-summary-content-item-name'>
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.LARGE_CARRY_BAG)}
        </div>
        <div className='checkout-summary-content-item-value'>
          {getCarryBagDataPrice(foodLines, carryBag, currencySymbol, currencyConversionFactor)}
        </div>
      </div>
    )
  );
};

export const getAddHopeDataPanel = (foodLines, addHope, currencySymbol, currencyConversionFactor) => {
  return (
    Boolean(getAddHopeId(foodLines, addHope, currencyConversionFactor)) && (
      <div className='flexBetweens checkout-summary-content-item' data-testid='checkout-add-hope-text'>
        <div className='checkout-summary-content-item-name'>
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.ADD_HOPE)}
        </div>
        <div className='checkout-summary-content-item-value'>
          {getAddHopeDataPrice(foodLines, addHope, currencySymbol, currencyConversionFactor)}
        </div>
      </div>
    )
  );
};

export const getBottleDepositCharge = (
  bottleDepositChargeTotal,
  currencySymbol,
  currencyConversionFactor,
  pageName = 'checkout',
) => {
  return (
    bottleDepositChargeTotal > 0 && (
      <div className={`flexBetweens ${pageName}`} data-testid={`${pageName}-summary-bottleDeposit-section`}>
        <div className={`${pageName}-summary-content-item-name`}>
          {translateWithI18Next(TRANSLATE_MAPPING_KEY?.BOTTLE_DEPOSIT)}
        </div>
        <div className={`${pageName}-summary-content-item-value`}>
          {displayPrice(bottleDepositChargeTotal, { currencySymbol, currencyConversionFactor }, '0.00')}
        </div>
      </div>
    )
  );
};

export const cookiesErrorMsg = handleOneTrustBanner => {
  return (
    <p className='cookies-text'>
      {translateWithI18Next(TRANSLATE_MAPPING_KEY?.PLEASE_MODIFY)}{' '}
      <span className='cookieslink-text' onClick={handleOneTrustBanner}>
        {translateWithI18Next(TRANSLATE_MAPPING_KEY?.COOKIES_SETTINGS)}
      </span>{' '}
      {translateWithI18Next(TRANSLATE_MAPPING_KEY?.PREFERENCE_TEXT)}
    </p>
  );
};
